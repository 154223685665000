import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl, access_token } from '../services/api';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import WelcomeBanner from '../partials/dashboard/WelcomeBanner';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Switch from 'react-input-switch';

function FeaturedRecordings() {
  const [refresh, setRefresh] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [books, setBooks] = useState([]);

  useEffect(() => {
    try {
      axios
        .get(`${baseUrl}/admin/bookRecordingweb`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then(async (response) => {
          console.log('dataaaaaaaa', response.data);
          const booksData = response.data;
          setBooks(booksData);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      if (error.message === 'Network Error') {
        alert('No internet connection');
      }
    }
  }, [refresh]);

  const handleToggleSelected = (recordingId, featured) => {
    console.log('Toggling featured status for recording ID:', recordingId);

    axios
      .patch(
        `${baseUrl}/admin/updateRecording/${recordingId}`,
        {
          featured: !featured,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        console.log('Recording updated:', response.data);
        alert('Recording updated successfully');
        // Update the local state to reflect the change
        setBooks((prevBooks) =>
          prevBooks.map((book) =>
            book.recordings.some((rec) => rec._id === recordingId)
              ? {
                  ...book,
                  recordings: book.recordings.map((rec) =>
                    rec._id === recordingId ? { ...rec, featured: !featured } : rec
                  ),
                }
              : book
          )
        );
      })
      .catch((error) => {
        console.error('Error updating recording:', error);
      });
  };

  const columns = [
    {
      dataField: 'bookCircle.book',
      text: 'Book',
      formatter: (cell, row) => {
        if (!cell) return null;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={cell.imageUrl}
              alt={cell.title}
              style={{ width: '40px', height: '60px', borderRadius: '5px', marginRight: '10px' }}
            />
            <span>{cell.title}</span>
          </div>
        );
      },
      filter: textFilter({
        placeholder: 'Search',
        className: 'form-control',
        delay: 1000,
        caseSensitive: false,
        style: {
          borderRadius: 5,
          margin: 5,
        },
      }),
    },
    {
      dataField: 'bookCircle.user.userInfo',
      text: 'Host',
      formatter: (cell, row) => {
        if (!cell) return null;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {cell.userProfileUrl ? (
              <img
                src={cell.userProfileUrl}
                alt={cell.firstName}
                style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
              />
            ) : (
              <div
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  backgroundColor: 'grey',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '10px',
                }}
              >
                {cell.firstName[0]}
              </div>
            )}
            <span>{cell.firstName}</span>
          </div>
        );
      },
    },
    {
      dataField: 'recordCount',
      text: 'Number of Records',
      formatter: (cell, row) => row.recordings.length,
    },
    {
      dataField: 'recordings',
      text: 'Selected',
      formatter: (cell, row) => {
        if (cell.length === 0) return null;
        const firstRecording = cell[0];
        return (
          <Switch
            onChange={() => handleToggleSelected(firstRecording._id, firstRecording.featured)}
            value={firstRecording.featured ? 1 : 0} // Ensure value reflects updated state
            onText="On"
            offText="Off"
            bsSize="mini"
            onColor="success"
            offColor="danger"
          />
        );
      },
    },
  ];

  const filteredBooks = books.filter((book) => book.bookCircle && book.bookCircle.book);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <WelcomeBanner />
            <BootstrapTable
              keyField="_id"
              data={filteredBooks}
              columns={columns}
              pagination={paginationFactory()}
              filter={filterFactory()}
            />
          </div>
        </main>
      </div>
    </div>
  );
}

export default FeaturedRecordings;
