// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyAymu3kLiTUB57aiN6hqi_k2ODAPSdB3NU",

  authDomain: "prime-apricot-370212.firebaseapp.com",

  databaseURL: "https://prime-apricot-370212-default-rtdb.europe-west1.firebasedatabase.app",

  projectId: "prime-apricot-370212",

  storageBucket: "prime-apricot-370212.appspot.com",

  messagingSenderId: "476360637848",

  appId: "1:476360637848:web:74f3e7149d87f90978bfed",

  measurementId: "G-MZ1Q6HG7W6"

};

// Initialize Firebase
  firebase.initializeApp(firebaseConfig);

export default firebase;