import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, Divider } from '@mui/material';
import { Star, StarBorder } from '@mui/icons-material';
import axios from 'axios';
import { access_token, baseUrl } from '../../services/api';
import FilterReports from '../actions/FilterReports';
import Reviews from './reviews'; // Import Reviews component

function DetailsForReviews() {
  const { state } = useLocation();
  const { id } = state; 
  const [filter, setFilter] = useState("All");
  const [reportedUser, setReportedUser] = useState({});
  const [reports, setReports] = useState([]);
  const [message, setMessage] = useState("");
  const [rating, setRating] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [updatedAt, setUpdatedAt] = useState();

  useEffect(() => {
    axios.get(`${baseUrl}/analytics/getReportedReviewsDetails/${id}`, {
      headers: { 'Authorization': `Bearer ${access_token}` }
    })
    .then((response) => {
      const data = response.data[0];
      setReportedUser(data.reportedUser);
      setMessage(data.message);
      setRating(data.rating);
      setReports(data.reports);
      setCreatedAt(data.createdAt);
      setUpdatedAt(data.updatedAt);
    })
    .catch(err => console.error(err));
  }, [id]);

  // Render stars for rating
  const renderStars = () => {
    const validRating = Math.min(Math.max(parseInt(rating, 10) || 0, 0), 5); // Clamp between 0 and 5
    const fullStars = Math.floor(validRating);
    const emptyStars = 5 - fullStars;

    return (
      <Box>
        {[...Array(fullStars)].map((_, i) => <Star key={i} style={{ color: 'gold' }} />)}
        {[...Array(emptyStars)].map((_, i) => <StarBorder key={i} style={{ color: 'gold' }} />)}
      </Box>
    );
  };

  // Filter the reports based on the selected filter
  const getFilteredReports = () => {
    if (filter === "All") return reports;
    return reports.filter(report => report.reportCatagory === filter);
  };

  // Get the filtered reports
  const filteredReports = getFilteredReports();

  return (
    <div className="">
      {/* Display Review Owner's Name */}
      <Box sx={{ padding: '12px' }}>
        <Typography 
          variant="h4" 
          sx={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'Poppins, sans-serif', color: '#333' }}
          className="text-slate-600 mb-1"
        >
          {reportedUser.userInfo?.firstName} {reportedUser.userInfo?.lastName}
        </Typography>

        <blockquote style={{ fontStyle: 'italic', borderLeft: '5px solid #ccc', padding: '10px 20px', color: '#555' }}>
          {message}
        </blockquote>

        <Box display="flex" alignItems="center" mt={2}>
          <Typography variant="body1" fontWeight="bold" marginRight={1}>Rating:</Typography>
          {renderStars()}
        </Box>

       
        <Typography variant="body1">
          <strong>Email:</strong> {reportedUser.googleInfo?.email}
        </Typography>
      </Box>

      {/* Reports Section */}
      <Typography variant="h4" align="center" className="text-2xl md:text-3xl text-slate-600 font-bold mb-1">Reports on this review</Typography>
      <hr style={{ marginBottom: '20px' }} />
      
      <Box sx={{ paddingBottom: '30px', textAlign: 'right', paddingRight: '30px' }}>
        <FilterReports filter={filter} setFilter={setFilter} />
      </Box>

      <Divider style={{ backgroundColor: 'grey', margin: '20px' }} />

      {/* Show "No Data" message if no reports are available */}
      {filteredReports.length === 0 ? (
        <Typography variant="body1" align="center" sx={{ color: 'gray', margin: '20px' }}>
          No data available for the selected filter.
        </Typography>
      ) : (
        <Reviews reports={filteredReports} />
      )}
    </div>
  );
}

export default DetailsForReviews;
