import React, { useState, useEffect } from "react";
import { Button, Form } from 'react-bootstrap';
import "../css/login.css";
// import { GoogleLogin } from 'react-google-login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import firebase from "../services/firebaseConfig";
import 'firebase/auth';
import jwt_decode from 'jwt-decode'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { redirect, useNavigate} from "react-router-dom"
import { refreshTokenSetup } from "./refreshToken";
import axios from "axios";
import {baseUrl,access_token, productionBaseUrl} from '../services/api';
import Dashboard from "./Dashboard";
import UserMenu from "../partials/header/UserMenu";
import ProductionDevelopmentMenu from "../partials/header/ProductionDevelopmentMenu";
export default function Login() {
  const navigate = useNavigate()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("")
  const [baseUrl, setBaseUrl] = useState(productionBaseUrl);

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }
  const onSuccess = (res) => {
    alert(
      `Logged in successfully welcome ${res.profileObj.name} 😍. \n See console for full profile object.`
    );
    refreshTokenSetup(res);
  };

  const onFailure = (res) => {
  };


  // if(email != "admin@123.com" && password != 'admin'){
  //     useEffect(
  //     setError("password and user name incorrect ")
  //     );
  //   }

  useEffect(()=>{
    console.log("SERver",localStorage.getItem("Server"));
    localStorage.removeItem("token")
    localStorage.removeItem("firstName")
    if (!localStorage.getItem("Server")) {
    localStorage.setItem("Server",baseUrl)
    localStorage.setItem("ServerStatus","Production")

      
    }


  },[])
  function handleSubmit() {  
    if(email === "admin@123.com" && password === 'admin'){
      navigate('/dashboard');
    }
    else{
     
      alert("password or username incorrect!");
    }
  }
    const responseGoogle =async (response) => {
      console.log(response);
      // const { id_token } = response.tokenObj;
    const idToken = response.credential.toString();

      // Create a Google Firebase credential with the ID token
      const credential = firebase.auth.GoogleAuthProvider.credential(idToken);
    // Send the ID token to your backend
    console.log(credential);
    const base=localStorage.getItem("Server")
    console.log("Serverrrrrr");
    console.log(base);
    try{

        await    axios
            .post(`${base}/auth/login/adminLogin`,{
           "uid":   idToken
            },
           )
            
            .then(async (response) => {
               console.log("baseUrlresponse",base);
               try {
                // Sign in with the credential
                const userCredential = await firebase.auth().signInWithCredential(credential);
                 
                // Access the user's information from the userCredential object
                const user = userCredential.user;
                console.log('Logged in user:', user);
                const token=response.data.accessToken
                const firstName=response.data.user.firstName
                localStorage.setItem('token', token);
                localStorage.setItem('firstName', firstName);
                localStorage.setItem("Server",base)
                navigate('/dashboard');
              } catch (error) {
                // Handle any errors that occur during sign-in
                console.error('Google sign-in error:', error);
              }

                }
                
            )     
            .catch(err => {
              console.error(err);
              
            });
           
            
    
      } catch (error) {
        if(error.message === 'Network Error'){
            alert('no internet connection');
        }}
    // Send the 'idToken' to your server using an HTTP request
    // Example: axios.post('/api/login', { idToken })
    // Handle the response from your server as needed
  };

  return (
  <div className="maincontainer">
    <div class="container-fluid">
        <div class="row no-gutter">
           
            <div class="bg-image">
              <h1 style={{textAlign:"right",margin:"20px"}}>
               <span style={{color:"white",padding:"5px"}}>{localStorage.getItem("ServerStatus")}</span> 
                <ProductionDevelopmentMenu baseUrl={baseUrl} setBaseUrl={setBaseUrl}/></h1>
              
            <div class="col-md-12 ">
              <div class="col-md-9  ">

             
                <div class="login d-flex align-items-center py-5">
                   
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-10 col-xl-7 mx-auto">
                                <center><h3 class="display-4" style={{padding:"10px"}}>Login</h3></center>
                                
                                    {/* <div class="form-group mb-3">
                                        <input id="inputEmail"
                                         type="email" placeholder="Email address" required=""  autoFocus
                                         value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    <div class="form-group mb-3">
                                        <input id="inputPassword" type="password"   placeholder="Password" required="" value={password}
                                    onChange={(e) => setPassword(e.target.value)} class="form-control rounded-pill border-0 shadow-sm px-4 text-primary" />
                                    </div> */}
                                    {/* <div class="custom-control custom-checkbox mb-3">
                                        <input id="customCheck1" type="checkbox" checked class="custom-control-input" />
                                        <label for="customCheck1" class="custom-control-label">Remember password</label>
                                    </div> */}
        <center>                          {/* <button type="submit" class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm" onClick={handleSubmit} disabled={!validateForm()}>Sign in</button> */}
        <GoogleOAuthProvider clientId="476360637848-b7bth8bhai7fvi135lqaskbj6h4jqjg4.apps.googleusercontent.com">

        <GoogleLogin
          onSuccess={responseGoogle
          //   credentialResponse => {
          //   const details = jwt_decode(credentialResponse.credential);
          //   ("details",details)
          //   (credentialResponse);
          //   navigate('/dashboard');
          // }
        
        }
          theme='filled_blue'
          shape='circle'
          logo_alignment="left"
          text="Sign In With Google"
          onError={() => {
            ('Login Failed');
          }}
        />
        
      </GoogleOAuthProvider>
      </center>  
                                    {/* <div class="text-center d-flex justify-content-between mt-4"><p>Code by <a href="#" class="font-italic text-muted"> 
                                            <u>Jassa</u></a></p></div> */}
                                
                            </div>
                        </div>
                    </div>
                </div> 
                
              </div>
            </div>
            </div>
        </div>
    </div>
  </div>

    
     
 
  
  );
 
}