import React, { useState, useEffect } from 'react';
import axios from "axios";
import { baseUrl, access_token } from '../../services/api';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';  // Sorting icon
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';  // Sorting icon
import { Pagination } from '@mui/material'; // For pagination

function BookTalk01() {
  const [searchVal, setSearchVal] = useState('');
  const [customers, setCustomers] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0); // Total records from API
  const [page, setPage] = useState(1); // Page state for pagination
  const [itemsPerPage] = useState(10); // Number of items per page
  const [searchedUser, setSearchedUser] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc'); // Sorting order

  // Fetch customers based on current page
  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get(`${baseUrl}/analytics/getTalksGroupedByUser/${page}`, {
          headers: {
            'Authorization': `Bearer ${access_token}`,
          },
        });
        
        const { createdTalksGroupedByUser, totalRecords } = response.data;

        if (createdTalksGroupedByUser.length === 0) {
          setCustomers([]);
        } else {
          setCustomers(createdTalksGroupedByUser);
          setTotalRecords(totalRecords); // Set the total number of records from the API
        }
      } catch (error) {
        if (error.message === 'Network Error') {
          alert('No internet connection');
        } else {
          console.error(error);
        }
      }
    };

    fetchCustomers();
  }, [page]);

  const handleSort = () => {
    const order = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(order);

    const sortedCustomers = [...customers].sort((a, b) => {
      return order === 'asc' ? a.total - b.total : b.total - a.total; // Sorting by total (No of Talks)
    });

    setCustomers(sortedCustomers);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchVal(value);
    setSearchedUser(customers.filter((customer) =>
      customer.user.userInfo.firstName.toLowerCase().includes(value.toLowerCase()) ||
      customer.user.googleInfo.email.toLowerCase().includes(value.toLowerCase())
    ));
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(totalRecords / itemsPerPage);

  return (
    <div style={{ borderRadius: '15px' }} className="col-span-full xl:col-span-12 bg-white shadow-lg border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800" style={{ width: '50%', display: 'inline-block' }}>
          Talks Created By Users
        </h2>
        <div style={{ float: 'right', display: 'flex', alignItems: 'center' }}>
          <input
            type="text"
            placeholder="Search by Name or Email"
            value={searchVal}
            onChange={handleSearch}
            className="border rounded px-2 py-1"
            style={{ width: '300px' }}
          />
        </div>
      </header>

      <div className="p-3">
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            <thead className="text-xs font-semibold uppercase text-slate-400 bg-slate-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Name</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Email</div>
                </th>
                <th className="p-2 whitespace-nowrap cursor-pointer" onClick={handleSort}>
                  <div className="font-semibold text-left flex items-center">
                    No Of Talks
                    {sortOrder === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Status</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-slate-100 bg-gray-50 striped-rows">
              {(searchedUser.length > 0 ? searchedUser : customers).map((customer) => (
                <tr key={customer._id} className="odd:bg-white even:bg-gray-100">
                  <td className="p-2 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
                        <img
                          className="rounded-full"
                          style={{ objectFit: 'cover', width: '40px', height: '40px' }}
                          src={!customer.user || customer.user.userInfo.userProfileUrl === ''
                            ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'
                            : customer.user.userInfo.userProfileUrl}
                          alt={customer.user.userInfo.firstName}
                        />
                      </div>
                      <div className="font-medium text-slate-800">
                        {customer.user.userInfo.firstName}
                      </div>
                    </div>
                  </td>
                  <td className="p-2 whitespace-nowrap">
                    <div className="text-left">{customer.user.googleInfo.email}</div>
                  </td>
                  <td className="p-2 whitespace-nowrap">
                    <div className="text-left font-medium text-green-500">{customer.total}</div>
                  </td>
                  <td className="p-2 whitespace-nowrap">
                    <button>{customer.user.userStatus === "BANNED" ? <strong style={{background: 'green', border: 'none', borderRadius: '2px', color: 'white', padding: '3px', fontSize: '11px'}} >Unban user</strong> : <strong style={{background: 'red', border: 'none', borderRadius: '2px', color: 'white', padding: '3px', fontSize: '11px'}} >Ban user</strong> }</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <Pagination
            count={totalPages} // Total number of pages based on total records and items per page
            page={page}
            onChange={(e, value) => setPage(value)} // Update page when pagination changes
            color="primary"
            className="mt-4"
            showFirstButton // Show first page button
            showLastButton // Show last page button
          />
        </div>
      </div>
    </div>
  );
}

export default BookTalk01;
