import React, { useState, useEffect } from 'react';
import axios from "axios";
import { baseUrl, access_token } from '../../services/api';
import AppString from '../components/AppStiring';

function DashboardCard10({ status, refresh, setRefresh }) {
  const [searchVal, setSearchVal] = useState();
  const [customers, setCustomers] = useState([]);
  const [searchedUser, setSearchedUser] = useState([]);
  const [afterSearch, setAfterSearch] = useState(0);

  useEffect(() => {
    try {
      axios
        .get(`${baseUrl}/analytics/getAllUsers`, {
          headers: {
            'Authorization': `Bearer ${access_token}`
          }
        })
        .then((response) => {
          setCustomers(response.data);
        })
        .catch(err => {
          console.error(err);
        });
    } catch (error) {
      if (error.message === 'Network Error') {
        alert('no internet connection');
      }
    }
  }, [refresh, searchVal, afterSearch]);

  const handleClick = (event, param) => {
    try {
      axios
        .patch(`${baseUrl}/admin/adminUpdate/${param.id}`, {
          "userStatus": `${param.value === "BANNED" ? "UNBANNED" : "BANNED"}`
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
          },
        })
        .then((response) => {
          alert(`USER ${param.value === "BANNED" ? "UNBANNED" : "BANNED"} SUCCESSFULLY!`);
          setRefresh(!refresh);
        })
        .catch(err => {
          console.error(err);
        });
    } catch (error) {
      if (error.message === 'Network Error') {
        alert ('no internet connection');
      }
    }
  };

  const handlePremium = (event, param) => {
    try {
      let newUserType;
      if (param.value === "FREEMIUM") {
        newUserType = "FREEMIUM";
      } else if (param.value === "PREMIUM") {
        newUserType = "PREMIUM";
      } else {
        newUserType = "PLATINUM";
      }

      axios
        .put(`${baseUrl}/admin/changeToFreemiumORPreemium/${param.id}`, {
          "usertype": newUserType
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
          },
        })
        .then((response) => {
          alert(`USER Type Changed to ${newUserType} SUCCESSFULLY!`);
          setRefresh(!refresh);
        })
        .catch(err => {
          console.error(err);
        });
    } catch (error) {
      if (error.message === 'Network Error') {
        alert('no internet connection');
      }
    }
  };

  const handleSearch = (event, param) => {
    try {
      axios
        .get(`${baseUrl}/admin/getUserByEmailOrPhoneNumber/${param}`, {
          headers: {
            'Authorization': `Bearer ${access_token}`
          },
        })
        .then((response) => {
          setSearchedUser(response.data);
        })
        .catch(err => {
          setSearchedUser([]);
          console.error(err);
        });
    } catch (error) {
      if (error.message === 'Network Error') {
        alert('no internet connection');
      }
    }
  };

  function handleChange(event) {
    setSearchVal(event.target.value);
    handleSearch(event, event.target.value);
  }

  return (
    <div style={{borderRadius: '15px'}} className="col-span-full xl:col-span-12 bg-white shadow-lg border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800" style={{ width: '50%', display: 'inline-block' }}> Total Users: <strong>{customers.length}</strong> </h2>
        <input placeholder='Search' style={{ float: "right", borderRadius: "10px", paddingRight: "5px", paddingLeft: "5px", width: "300px", border: "solid 0.7px" }} onChange={handleChange} />
      </header>
      <div className="p-3">
        {/* Table */}
        <div className="overflow-x-auto" style={{ height: "350px", overflow: 'scroll' }}>
          <table className="table-auto w-full text-left border-collapse">
            {/* Table header */}
            <thead className="bg-gray-100 text-sm font-semibold uppercase text-gray-800">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Name</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Email</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Status</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">User Type</div>
                </th>
              </tr>
            </thead>
            {/* Table body with striped rows */}
            <tbody className="text-sm divide-y divide-gray-200">
              {searchedUser.length === 0 ?
                customers.map(customer => {
                  return (
                    <tr key={customer._id} className="odd:bg-gray-50">
                      <td className="p-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
                            <img className="rounded-full" style={{ objectFit: 'cover', width: "40px", height: "40px" }} src={customer.userInfo.userProfileUrl === "" ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png' : customer.userInfo.userProfileUrl} alt={customer.userInfo.firstName} />
                          </div>
                          <div className="font-medium text-slate-800"><AppString>{customer.userInfo.firstName}</AppString></div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{customer.googleInfo && customer.googleInfo.email}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium " onClick={event => handleClick(event, { "id": customer._id, "value": customer.userStatus })}>
                          <button>{customer.userStatus === "BANNED" ? <strong style={{background: 'green', border: 'none', borderRadius: '2px', color: 'white', padding: '3px', fontSize: '11px'}} >Unban user</strong> : <strong style={{background: 'red', border: 'none', borderRadius: '2px', color: 'white', padding: '3px', fontSize: '11px'}} >Ban user</strong> }</button>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium text-green-500">
                          <select
                            value={customer.usertype}
                            onChange={(event) => handlePremium(event, { "id": customer._id, "value": event.target.value })}
                          >
                            <option value="FREEMIUM">Freemium</option>
                            <option value="PREMIUM">Premium</option>
                            <option value="PLATINUM">Platinum</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                  )
                }) :
                searchedUser.map(customer => {
                  return (
                    <tr key={customer._id} className="odd:bg-gray-150">
                      <td className="p-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
                            <img className="rounded-full" style={{ objectFit: 'cover', width: "40px", height: "40px" }} src={customer.userInfo.userProfileUrl === "" ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png' : customer.userInfo.userProfileUrl} alt={customer.userInfo.firstName} />
                          </div>
                          <div className="font-medium text-slate-800"><AppString>{customer.userInfo.firstName}</AppString></div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{customer.googleInfo && customer.googleInfo.email}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium text-green-500" onClick={event => handleClick(event, { "id": customer._id, "value": customer.userStatus })}>
                        <button>{customer.userStatus === "BANNED" ? <strong style={{background: 'green', border: 'none', borderRadius: '2px', color: 'white', padding: '3px', fontSize: '11px'}} >Unban user</strong> : <strong style={{background: 'red', border: 'none', borderRadius: '2px', color: 'white', padding: '3px', fontSize: '11px'}} >Ban user</strong> }</button>
                       </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium text-green-500">
                          <select
                            value={customer.usertype}
                            onChange={(event) => handlePremium(event, { "id": customer._id, "value": event.target.value })}
                          >
                            <option value="FREEMIUM">Freemium</option>
                            <option value="PREMIUM">Premium</option>
                            <option value="PLATINUM">Platinum</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DashboardCard10;
