import React, { useState, useEffect } from 'react';
import axios from "axios";
import { baseUrl, access_token } from '../services/api';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import WelcomeBanner from '../partials/dashboard/WelcomeBanner';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function SetUserTime() {
  const [refresh, setRefresh] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [editModeRowId, setEditModeRowId] = useState(null);
  const [editedSpeakingTime, setEditedSpeakingTime] = useState('');
  const [editedListeningTime, setEditedListeningTime] = useState('');
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    try {
      axios
        .get(`${baseUrl}/analytics/getAllUsers`, {
          headers: {
            'Authorization': `Bearer ${access_token}`
          }
        })
        .then(async (response) => {
          console.log(response.data);
          const usersData = response.data;
        //   const userIds = usersData.map(user => user._id);
        //   const promises = userIds.map(userId => axios.get(`${baseUrl}/users/getRemainingTimeweb/${userId}`, {
        //     headers: {
        //       'Authorization': `Bearer ${access_token}`
        //     }
        //   }));
        //   const remainingTimesResponses = await Promise.all(promises);
        //   const updatedUsersData = usersData.map((user, index) => {
        //     const remainingTimeData = remainingTimesResponses[index].data;
        //     return {
        //       ...user,
        //       userInfo: {
        //         ...user.userInfo,
        //         speakingTime: remainingTimeData.remainingTimeAsSpeaker,
        //         listeningTime: remainingTimeData.remainingTimeAsListener
        //       }
        //     };
        //   });
          // Update the state with the updated user data
          setCustomers(usersData);
        })
        .catch(err => {
          console.error(err);
        });
    } catch (error) {
      if (error.message === 'Network Error') {
        alert('no internet connection');
      }
    }
  }, [refresh]);
  

const handleEdit = ( rowId, speakingTime, listeningTime) => {
  console.log("the idddddd",rowId)
  setEditModeRowId(rowId);
  setEditedSpeakingTime(speakingTime);
  setEditedListeningTime(listeningTime);
  setUserId(rowId); 
};
  
    const handleSave = async () => {
       
        try {
          const updatedCustomers = customers.map(customer => {
            if (customer.id === editModeRowId) {
              return {
                ...customer,
                userInfo: {
                  ...customer.userInfo,
                  speakingTime: editedSpeakingTime,
                  listeningTime: editedListeningTime
                }
              };
            }
            return customer;
          });
      
          // Make API call to update the user's time using userId
          const response = await axios.patch(`${baseUrl}/users/timeUpdate/${userId}`, {
            speakingTime: editedSpeakingTime,
            listeningTime: editedListeningTime
          },{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${access_token}`
            }
          });
      
          console.log('User time updated:', response.data);
         
     
          setCustomers(updatedCustomers);
          setEditModeRowId(null);
          setEditedSpeakingTime('');
          setEditedListeningTime('');
          setUserId(null); 
          alert('Updated successfully!')
          window.location.reload(); 
          
        } catch (error) {
          console.error('Error updating user time:', error);
         alert("Someting went wrong please try again")
        }
      };
      

  const handleCloseModal = () => {
    setEditModeRowId(null);
    setEditedSpeakingTime('');
    setEditedListeningTime('');
  };

  const columns = [
    {
      dataField: 'userInfo.userProfileUrl',
      text: 'Name',
     
      formatter: (cell, row) => (
        <div className="flex items-center">
          <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
            <img className="rounded-full" style={{ objectFit: 'cover', width: "40px", height: "40px" }} src={row.userInfo.userProfileUrl === "" ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png' : row.userInfo.userProfileUrl} alt={row.userInfo.firstName} />
          </div>
          <div className="font-medium text-slate-800">{row.userInfo.firstName.length > 20 ? `${row.userInfo.firstName.substring(0, 10)}...` : row.userInfo.firstName}</div>
        </div>
      ),
    },
    {
      dataField: 'googleInfo.email',
      text: 'Email',
      filter: textFilter({
        placeholder: 'Search', 
        className: 'from-control', 
        delay: 1000,  
        caseSensitive: false,
        style: {
           
            borderRadius: 5,
            marigin: 5
            
        }
      }),
    
      
    },
    {
      dataField: 'userInfo.speakingTime',
      text: 'Speaking Time',
      formatter: (cell, row) => (
        <div>
          {row.id === editModeRowId ? (
            <input 
              type="text" 
              value={editedSpeakingTime} 
              onChange={(e) => setEditedSpeakingTime(e.target.value)} 
            />
          ) : (
            cell
          )}
        </div>
      ),
    },
    {
      dataField: 'userInfo.listeningTime',
      text: 'Listening Time',
      formatter: (cell, row) => (
        <div>
          {row.id === editModeRowId ? (
            <input 
              type="text" 
              value={editedListeningTime} 
              onChange={(e) => setEditedListeningTime(e.target.value)} 
            />
          ) : (
            cell
          )}
        </div>
      ),
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => (
        <div className="flex items-center">
         <button className="mr-2 text-blue-500 hover:text-blue-700" onClick={() => handleEdit(row._id, row.userInfo.speakingTime, row.userInfo.listeningTime)}>Edit</button>
        <div>{cell}</div>
        </div>
      ),
    },
  ];

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <WelcomeBanner />
            <BootstrapTable
              keyField="id"
              data={customers}
              columns={columns}
              pagination={paginationFactory({
                paginationOptions: {
                  custom: true,
                  paginationClass: 'custom-pagination-class',
                },
              })}
              filter={filterFactory({})}
            />
            <Modal show={editModeRowId !== null} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Time</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mb-3">
                  <label htmlFor="speakingTimeInput" className="form-label">Speaking Time:</label>
                  <input 
                    type="text" 
                    className="form-control" 
                    id="speakingTimeInput" 
                    value={editedSpeakingTime} 
                    onChange={(e) => setEditedSpeakingTime(e.target.value)} 
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="listeningTimeInput" className="form-label">Listening Time:</label>
                  <input 
                    type="text" 
                    className="form-control" 
                    id="listeningTimeInput" 
                    value={editedListeningTime} 
                    onChange={(e) => setEditedListeningTime(e.target.value)} 
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button style={{backgroundColor: 'grey'}} onClick={handleCloseModal}>Cancel</Button>
                <Button style={{backgroundColor: 'blue'}} onClick={handleSave}>Save</Button>
              </Modal.Footer>
            </Modal>
          </div>
        </main>
      </div>
    </div>
  );
}

export default SetUserTime;
