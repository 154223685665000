import React, { useState, useEffect } from "react";
import {  Form } from 'react-bootstrap';
import "../../css/login.css";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useNavigate} from "react-router-dom"

import axios from "axios";
import {baseUrl,access_token} from '../../services/api';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { First } from "react-bootstrap/esm/PageItem";

export default function AddBadWords() {
  const [badWord, setBadWord] = useState()


 
  
  function handleEdit(event, val) {
    
        try{

        axios
        .post(`${baseUrl}/admin/custom-words`,{
              "word":badWord,
              "AddOrRemove":val
        },
        
        {  headers: {
          'Authorization': `Bearer ${access_token}`
        }})
        
        .then((response) => {
         
            alert(" data  changed sucessully")

            
          }

        )     
        .catch(err => {
            alert(err.response.data.error)
          console.error(err);
          
        });
       
        

  } catch (error) {
    if(error.message === 'Network Error'){
        alert('no internet connection');
    }
    
}

     
  }

  return (
  <div className="maincontainer">
    <div class="container-fluid">
        <div class="row no-gutter">
           
            <div class="col-md-12 ">
              <div class="col-md-9  ">

             
                <div class="login d-flex align-items-center py-5">
                   
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-10 col-xl-7 mx-auto">
                                <center><h1 class="display-4" style={{color:"black"}}>Add Bad Word</h1></center>
                                <form>
                                    <div class="form-group mb-3">
                                        <label  for="badword">which word do you think as offensive or do you want to remove:</label>
                                        <input id="badword" 
                                         type="text" placeholder={"Enter  words to remove or add as offensive here "} required  autoFocus
                                         value={badWord}
                                        onChange={(e) => setBadWord(e.target.value)}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                    
                                    <div>
      <div  class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill" style={{backgroundColor:"blue",margin:"20px"}} onClick={event => handleEdit(event, 'ADD')} >Add</div>
      <div  class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill" style={{backgroundColor:"blue",margin:"20px"}} onClick={event => handleEdit(event, 'REMOVE')} >Remove</div>
     
    </div>
                                    {/* <div class="text-center d-flex justify-content-between mt-4"><p>Code by <a href="#" class="font-italic text-muted"> 
                                            <u>Jassa</u></a></p></div> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div> 
                
            </div>
            </div>
        </div>
    </div>
  </div>

  
  );
 
}