import React, { useState, useEffect } from 'react';
import axios from "axios";
import { baseUrl, access_token } from '../../services/api';
import SearchIcon from '@mui/icons-material/Search';
import AppString from '../components/AppStiring';

function BookTalk03() {
  const [searchVal, setSearchVal] = useState('');
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [page, setPage] = useState(1);
  const [buttonText, setButtonText] = useState("See More");

  useEffect(() => {
    fetchCustomers();
  }, [page]);

  const fetchCustomers = () => {
    axios
      .get(`${baseUrl}/analytics/getCreatedTalksGroupedByBookId/${page}`, {
        headers: { 'Authorization': `Bearer ${access_token}` }
      })
      .then((response) => {
        const fetchedCustomers = response.data.createdTalksGroupedByBookId;
        if (fetchedCustomers.length === 0) {
          setButtonText("See Less");
          setCustomers(customers.slice(0, 10));
          setPage(1);
        } else {
          setCustomers(fetchedCustomers);
          setFilteredCustomers(fetchedCustomers);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  // Filter the customers list based on search input
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchVal(value);

    if (value) {
      const filtered = customers.filter((customer) => 
        customer.book.title.toLowerCase().includes(value)
      );
      setFilteredCustomers(filtered);
    } else {
      setFilteredCustomers(customers); // Reset to full list when search is cleared
    }
  };

  const handleLoadMore = () => {
    if (buttonText === "See Less") {
      setPage(1);
    } else {
      setPage(customers.length < 10 ? page : page + 1);
    }
  };

  const handleLoadLess = () => {
    setPage(page <= 1 ? 1 : page - 1);
  };

  return (
    <div style={{ borderRadius: '15px' }} className="col-span-full xl:col-span-6 bg-white shadow-lg border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100 flex justify-between">
        <h2 className="font-semibold text-slate-800">{"Most Talks created Books"}</h2>
        <div className="flex items-center">
          <input 
            type="text" 
            placeholder="Search by Book Title" 
            className="border rounded px-2 py-1 mr-2"
            onChange={handleSearch}
            value={searchVal}
          />
       
        </div>
      </header>
      <div className="p-3">
        <div className="overflow-y-auto" style={{ maxHeight: '400px' }}>
          <table className="table-auto w-full">
            <thead className="text-xs font-semibold uppercase text-slate-400 bg-slate-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Title</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Number of Talks</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-slate-100">
              {filteredCustomers.map(customer => (
                <tr key={customer._id} className="bg-white hover:bg-slate-50">
                  <td className="p-2 whitespace-nowrap">
                    <div className="flex items-start">
                      <div className="w-16 h-24 shrink-0 mr-2 sm:mr-3 relative">
                        <img 
                          className="rounded-bl-md rounded-br-md rounded-tr-lg" 
                          style={{ objectFit: 'cover', width: '100%', height: '100%' }} 
                          src={!customer.book || customer.book.imageUrl === "" ? 
                            'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png' 
                            : customer.book.imageUrl} 
                          alt={customer.book.title} 
                        />
                      </div>
                      <div className="font-medium text-slate-800">
                        <AppString>{customer.book.title}</AppString>
                      </div>
                    </div>
                  </td>
                  <td className="p-2 whitespace-nowrap">
                    <div className="text-left font-medium text-green-500">{customer.total}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div style={{ width: "100%", textAlign: "right" }}>
            {page > 1 && 
              <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white" onClick={handleLoadLess}>Previous</button>}
            {customers?.length >= 10 && 
              <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-2" onClick={handleLoadMore}>Next</button>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookTalk03;
