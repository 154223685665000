import React,{useState,useEffect}  from 'react';
import axios from "axios";
import {baseUrl,access_token} from '../../services/api';
import AppString from '../components/AppStiring';
import { Details } from '@mui/icons-material';
import {
    useHistory,
    useNavigate
  } from "react-router-dom";
function DashboardCard31({userType,status,refresh,setRefresh}) {
    const navigate = useNavigate()
 const[ customers,setCustomers]=useState([])
  useEffect(()=>{

    try{

       
          axios
          .get(`${baseUrl}/analytics/getReportedReviews`,{  headers: {
            'Authorization': `Bearer ${access_token}`
          }})
          
          .then((response) => {
    
              console.log("jjhhhhdddd 31",response);
              setCustomers(response.data)
              console.log(response.data);
     
      
          }
          
          )     
          .catch(err => {
            console.error(err);
            
          });
        
  
    } catch (error) {
      if(error.message === 'Network Error'){
          alert('no internet connection');
      }
}},[refresh]

)
const handleClick = (event, param) => {

  try{
 
    axios
    .patch(`${baseUrl}/admin/reviewStatus/${param}`,{
      "commentStatus":"BANNED"
  },{  headers: {'Content-Type': 'application/json',
      'Authorization': `Bearer ${access_token}`
    },
  })
    
    .then((response) => {
      console.log(("kkkkjjjjsss",response));
     alert("Review BANNED SUCCESSFULLY!")
      setRefresh(!refresh)

    }
    
    )     
    .catch(err => {
      console.error(err);
      
    });
   
    

} catch (error) {
if(error.message === 'Network Error'){
    alert('no internet connection');
}
}

};

  return (
     
    <div style={{borderRadius: '15px'}} className="flex flex-col col-span-full sm:col-span-6 xl:col-span-6 bg-white shadow-lg rounded-xlg border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800">{`Reported Reviews:  ${customers.length==0?"":customers.length}`}</h2>
      </header>
      <div className="p-3" style={{height:"350px", overflow: 'scroll'}}>

        {/* Table */}
        {customers.length==0?<div style={{textAlign:'center',margin:'auto'}}>No ReportedUser user</div>:

        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-400 bg-slate-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Reviewer Name</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Review Details</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Status</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Number of report on Review</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-100">
              {
                customers.map(customer => {
                  return (
                   
                    <tr key={customer.length==0?"":customer._id} >
                      
                      <td className="p-2 whitespace-nowrap" >
                        <div className="flex items-center">
                          <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
                            <img className="rounded-full" src={customer._id.length==0?"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png":customer.reportedUser.userInfo.userProfileUrl==""?"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png":customer.reportedUser.userInfo.userProfileUrl} style={{objectFit:'cover',width:"40px", height:"40px"}} alt={customer._id.length==0?"deleted user":customer.reportedUser.userInfo.firstName} />
                          </div>
                          <div className="font-medium text-slate-800">
                            <AppString len={17}>
                            {customer.length==0?"deleted user":customer.reportedUser.userInfo.firstName}

                            </AppString>
                            </div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap" onClick={()=>{
                        navigate('/reportPage/report',{state:{id:customer._id}})
                      }}>
                        <div className="text-left">
                        <AppString len={35}>
                              <button style={{padding: '3px', background: 'blue', color: 'white', borderRadius: '5px', fontSize: '11px'}}>View</button>
                            </AppString>
                            
                            </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
{                    customer.length!==0&&    <div className="text-left font-medium text-green-500" onClick={event => handleClick(event, customer._id)} ><button><strong style={{background: 'red', border: 'none', borderRadius: '2px', color: 'white', padding: '3px', fontSize: '11px'}} >{status}</strong></button></div>
}                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-lg text-center">{customer.total}</div>
                      </td>
                      
                    </tr>
                  )
                })
              }
            </tbody>
          
          </table>

        </div>
}
      </div>
    </div>
  );
}

export default DashboardCard31;
