import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Switch from "react-input-switch";
import { addBookToChunk, removeBookFromChunk } from "../../services/bookchunk.service";
import { Settings } from "@mui/icons-material";
import { updateBookState } from "../../services/books.service";

function MyVerticallyCenteredModal(props) {
  const { popular, recommended, trendingGenre, featured, bookId, onUpdate, bookTitle } =
    props;
  const [isFeatured, setIsFeatured] = useState(featured);
  const [isRecommended, setIsRecommended] = useState(recommended);
  const [isTrendingGenre, setIsTrendingGenre] = useState(trendingGenre);
  const [isPopular, setIsPopular] = useState(popular);
  return (
    <Modal
    {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={()=>{
        console.log('called');
        onUpdate(
        isFeatured,
        isPopular,
        isRecommended,
        isTrendingGenre
      );props.onHide()}}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {bookTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Centered Modal</h4>
        <table>
          <tbody>
            <tr>
              <td>Featured</td>
              <td>
                <Switch
                  value={isFeatured ? "0" : "1"}
                  on="0"
                  off="1"
                  onChange={() => {
                    updateBookState(bookId,'featured',!isFeatured).then((response) =>{});
                    setIsFeatured(!isFeatured);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Recommended</td>
              <td>
                <Switch
                  value={isRecommended ? "0" : "1"}
                  on="0"
                  off="1"
                  onChange={() => {
                    updateBookState(bookId,'recommended',!isRecommended).then((response) =>{});
                    setIsRecommended(!isRecommended);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Popular</td>
              <td>
                <Switch
                  value={isPopular ? "0" : "1"}
                  on="0"
                  off="1"
                  onChange={() => {
                    updateBookState(bookId,'popular',!isPopular).then((response) =>{});
                    setIsPopular(!isPopular);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>Trending Genre</td>
              <td>
                <Switch
                  value={isTrendingGenre ? "0" : "1"}
                  on="0"
                  off="1"
                  onChange={() => {
                    isTrendingGenre?removeBookFromChunk('TRENDING_GENRE_BOOK',bookId).then((response) =>{}):addBookToChunk('TRENDING_GENRE_BOOK',bookId).then((response) =>{});
                    setIsTrendingGenre(!isTrendingGenre);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>{onUpdate(
                    isFeatured,
                    isPopular,
                    isRecommended,
                    isTrendingGenre
                  );props.onHide()}} className="text-green-500">Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function BookChunkModal({
  popular,
  recommended,
  trendingGenre,
  featured,
  bookId,
  onUpdate,
  bookTitle,
}) {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        <Settings color="info" />
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        popular={popular}
        recommended={recommended}
        trendingGenre={trendingGenre}
        featured={featured}
        bookId={bookId}
        onUpdate={onUpdate}
        bookTitle={bookTitle}
      />
    </>
  );
}
