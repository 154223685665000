import React, { useEffect, useState } from 'react';
import BarChart from '../../charts/BarChart'; // Update the import path
import axios from "axios";
import { baseUrl, access_token } from '../../services/api';
import { subDays } from 'date-fns';
import { SearchType, tailwindConfig } from '../../utils/Utils';

function DashboardCard08({ startDate }) {
  const [activeTab, setActiveTab] = useState(SearchType.WEEKLY);
  const [data, setData] = useState([]);
  const [previousWeekData, setPreviousWeekData] = useState([]);
  const [currentWeekData, setCurrentWeekData] = useState([0, 0, 0, 0, 0, 0, 0, 0]);

  const chartData = {
    labels: data,
    datasets: [
      {
        label: 'Pending user in invitation',
        data: currentWeekData,
        backgroundColor: tailwindConfig().theme.colors.indigo[500],
      },
      {
        label: 'Accepted user from Invitation',
        data: previousWeekData,
        backgroundColor: tailwindConfig().theme.colors.green[400],
      },
    ],
  };

  useEffect(() => {
    handleClick(SearchType.WEEKLY);
  }, [startDate]);

  const handleClick = (param) => {
    setActiveTab(param);
    currentWeekData.fill(0);

    const searchType = param;
    const endDate = subDays(startDate, searchType);

    function lastNDays(d) {
      d = +d;
      const days = [];
      let i = searchType;
      while (i--) {
        days.push((new Date(d += 8.64e7).toISOString().substring(0, 10)));
      }
      return days;
    }

    const arrThisWeekLabel = lastNDays(endDate);
    setData(arrThisWeekLabel);

    const arrWeekBeforeLabel = lastNDays(endDate);
    const arrThisWeekValue = Array(arrThisWeekLabel.length).fill(0);
    const arrWeekBeforeValue = Array(arrWeekBeforeLabel.length).fill(0);

    try {
      axios
        .get(`${baseUrl}/analytics/getNumberOfNewUsersAcceptedAndPendingInvitation/${endDate.toISOString().substring(0, 10)}/date/${new Date(startDate).toISOString().substring(0, 10)}`, {
          headers: {
            'Authorization': `Bearer ${access_token}`
          }
        })
        .then((response) => {
          const arrayAccepted = response.data.userNotAcceptedGroupedByDate;
          const arrayNotAccepted = response.data.userAcceptedGroupedByDate;

          arrayAccepted.forEach(item => {
            if (item._id.yearMonth <= endDate) {
              arrWeekBeforeValue.forEach((value, index) => {
                if (item._id.yearMonth === arrWeekBeforeLabel[index]) {
                  arrWeekBeforeValue[index] = item.total;
                }
              });
            } else {
              arrThisWeekValue.forEach((value, index) => {
                if (item._id.yearMonth === arrThisWeekLabel[index]) {
                  arrThisWeekValue[index] = item.total;
                }
              });
            }
          });

          arrayNotAccepted.forEach(item => {
            if (item._id.yearMonth <= endDate) {
              arrWeekBeforeValue.forEach((value, index) => {
                if (item._id.yearMonth === arrWeekBeforeLabel[index]) {
                  arrWeekBeforeValue[index] = item.total;
                }
              });
            } else {
              arrThisWeekValue.forEach((value, index) => {
                if (item._id.yearMonth === arrThisWeekLabel[index]) {
                  arrWeekBeforeValue[index] = item.total;
                }
              });
            }
          });

          setCurrentWeekData(arrThisWeekValue);
          setPreviousWeekData(arrWeekBeforeValue);
        })
        .catch(err => {
          console.error(err);
        });
    } catch (error) {
      if (error.message === 'Network Error') {
        alert('no internet connection');
      }
    }
  };

  return (
    <div style={{borderRadius: '15px'}} className="flex flex-col col-span-full sm:col-span-6 xl:col-span-6 bg-white shadow-lg rounded-xlg border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100 flex items-center">
        <h2 className="font-semibold text-slate-800 text-sm">New users</h2>
        <div className="ml-auto flex">
          <button
            onClick={() => handleClick(SearchType.WEEKLY)}
            className={`px-3 py-1 mx-1 rounded-lg text-sm font-medium transition-colors duration-300 ${activeTab === SearchType.WEEKLY ? 'bg-blue-500 text-white shadow-lg' : 'bg-white text-blue-500 border border-blue-500 hover:bg-blue-100'}`}
          >
            Weekly
          </button>
          <button
            onClick={() => handleClick(SearchType.MONTHLY)}
            className={`px-4 py-2 mx-1 rounded-lg text-sm font-medium transition-colors duration-300 ${activeTab === SearchType.MONTHLY ? 'bg-blue-500 text-white shadow-lg' : 'bg-white text-blue-500 border border-blue-500 hover:bg-blue-100'}`}
          >
            Monthly
          </button>
          <button
            onClick={() => handleClick(SearchType.QUARTERLY)}
            className={`px-4 py-2 mx-1 rounded-lg text-sm font-medium transition-colors duration-300 ${activeTab === SearchType.QUARTERLY ? 'bg-blue-500 text-white shadow-lg' : 'bg-white text-blue-500 border border-blue-500 hover:bg-blue-100'}`}
          >
            Quarterly
          </button>
        </div>
      </header>
      <BarChart data={chartData} width={595} height={248} />
    </div>
  );
}

export default DashboardCard08;
