import React,{useState,useEffect}  from 'react';
import axios from "axios";
import {baseUrl,access_token} from '../../services/api';
import SearchIcon from '@mui/icons-material/Search';
import AppString from '../components/AppStiring';
function WaitedListCard() {
  const[searchVal,setSearchVal]=useState();

 const[ customers,setCustomers]=useState([])
 const[ refresh,setReferash]=useState(true)

 const[ page,setPage]=useState(1)
 const[ buttonText,setButtonText]=useState("See More")
 const[ searchedUser,setSearchedUser]=useState([])

  useEffect(()=>{

    try{

  
          axios
          .get(`${baseUrl}/admin/getWaitedListPhoneNumber`,{  headers: {
            'Authorization': `Bearer ${access_token}`
          }})
          
          .then((response) => {
       
              setCustomers(response.data)
      
          }
          
          )     
          .catch(err => {
            console.error(err);
            
          });
         
          
  
    } catch (error) {
      if(error.message === 'Network Error'){
          alert('no internet connection');
      }
      
}},[page]

)
const handleLoadMore=()=>{
  if (buttonText=="See Less") {
    setReferash(false)
  }
  setPage(customers.length<10?page:page+1)

}
const handleLoadLess=()=>{
  if (buttonText=="See Less") {
    setReferash(false)
  }
  setPage(page<=1?1:page-1)

}
const handleClick = (event, param) => {
 console.log("param handle click");
  console.log(param);


  
  console.log(event);
  console.log(param);
};



  return (
    // <div>
    //   {
    //   customers.map((customer,)=>
    //   JSON.stringify(customer.user.userInfo.userProfileUrl===""?'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png':customer.user.userInfo.userProfileUrl)
    // //   ||customer.user.userInfo.userProfileUrl===""?'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png':customer.userInfo.userProfileUrl
      
    //   )
        
    //   }  
    // </div>
    <div className="col-span-full xl:col-span-12 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100" >
        <h2 className="font-semibold text-slate-800" style={{width:'50%',display:'inline-block'}}>{"Phone Number in waited list"}</h2>

        {/* <input placeholder='Search' style={{float:"right",borderRadius:"10px",paddingRight:"5px",paddingLeft:"5px",border:"solid"}} onChange={handleChange}/>
        <SearchIcon style={{float:"right",margin:"5px"}} onClick={event => handleSearch(event, searchVal)}/>
       */}
      </header>
      <div className="p-3">

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-400 bg-slate-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Phone Number</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Total times of try</div>
                </th>
          
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-100">
              {
                customers.map(customer => {
                  return (
                    <tr key={customer._id}>
                      <td className="p-2 whitespace-nowrap">
                        <div className="flex items-center">
                      
                          <div className="font-medium text-slate-800">{customer._id}  </div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{customer.total}</div>
                      </td>
                  
                    </tr>
                  )
                })}
            </tbody>
          </table>
          <div style={{width:"100%",textAlign:"right"}}>
 {  page>1&&       <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white" onClick={handleLoadLess}>{"Previous"}</button>
}
{  customers.length>=10&&      <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white" style={{textAlign:"right",margin:"10px"}} onClick={handleLoadMore}>{"Next"}</button>
}        </div>
        </div>

      </div>
    </div>
  );
}

export default WaitedListCard;
