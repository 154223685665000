// authService.js

export const isAuthenticated = () => {
    const token = localStorage.getItem('token'); // Assuming the token is stored in local storage
    
    // Check if the token exists and is valid (e.g., not expired)
    // You can use libraries like `jwt-decode` to decode and check the token
   
   
    return token
  };
  
  export const isTokenExpired = (token) => {
    // Implement the logic to check if the token is expired
    // Return `true` if expired, `false` otherwise
  };
  