import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography } from '@mui/material';

function Reviews({ reports }) {
  // Function to format the date in "Sep 13, 2024" format
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <TableContainer component={Paper} sx={{ margin: '20px', marginRight: '40px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', overflow: 'hidden' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={{ fontWeight: 'bold', padding: '12px' }}>Name</TableCell>
            <TableCell align="left" sx={{ fontWeight: 'bold', padding: '12px' }}>Date</TableCell>
            <TableCell align="left" sx={{ fontWeight: 'bold', padding: '12px' }}>Report</TableCell>
            <TableCell align="left" sx={{ fontWeight: 'bold', padding: '12px' }}>Category</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.length > 0 ? (
            reports.map((report) => (
              <TableRow key={report._id} sx={{ minHeight: '60px' }}>
                <TableCell align="left" sx={{ padding: '12px' }}>
                  <Box display="flex" alignItems="left" justifyContent="left">
                    <Box
                      sx={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'left',
                        marginRight: '10px'
                      }}
                    >
                      <img
                        src={report.reportingUser?.userInfo?.userProfileUrl || 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'}
                        alt="Profile"
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover'
                        }}
                      />
                    </Box>
                    <Typography noWrap>{report.reportingUser?.userInfo?.firstName} {report.reportingUser?.userInfo?.lastName}</Typography>
                  </Box>
                </TableCell>
                <TableCell align="left" sx={{ padding: '12px' }}>
                  {formatDate(report.updatedAt)}
                </TableCell>
                <TableCell align="left" sx={{ padding: '12px' }}>
                  <Typography noWrap>{report.reason || 'N/A'}</Typography>
                </TableCell>
                <TableCell align="left" sx={{ padding: '12px', color: 'red' }}>
                  {report.reportCatagory}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center" sx={{ padding: '12px' }}>
                <Typography>No data available</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Reviews;
