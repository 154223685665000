import React, { useEffect, useState } from "react";
import {
  getMoniroFeaturedReviews,
  getReviews,
  updateReviewState,
} from "../../services/books.service";
import Spinner from 'react-bootstrap/Spinner';
import ReviewCard from "../components/ReviewCard";
import Switch from "react-input-switch";

function DashboardCard33() {
  const [platinumReviews, setPlatinumReviews] = useState();
  const [premiumReviews, setPremiumReviews] = useState();
  const [freemiumReviews, setFreemiumReviews] = useState();
  const [featuredReviews, setFeaturedReviews] = useState([]);
  const getMappedReview = (data)=>data.map(({ _id,userInfo,message,rating,}) =>
   {const { userName, userProfileUrl } = userInfo[0]?userInfo[0].userInfo:userInfo.userInfo; 
  const userType = userInfo[0]?userInfo[0].usertype:userInfo.usertype; 
  return { _id, message, rating, userName, imageUrl: userProfileUrl, userType };});

  useEffect(() => {
    getMoniroFeaturedReviews()
      .then((response) => {
        // console.log("RESPPONSE.getReviews.FEATURED", response.data);
        setFeaturedReviews(getMappedReview(response.data));
      })
      .catch((reason) =>
        console.log("ERROR WITH REASON.getMoniroFeaturedReviews:", reason)
      );
    getReviews("PLATINUM")
      .then((response) => {
        // console.log("RESPPONSE.getReviews.PLATINUM", response.data);
        setPlatinumReviews(getMappedReview(response.data).filter(({featured}) => !featured));
      })
      .catch((reason) => console.log("ERROR WITH REASON.getReviews:", reason));
    getReviews("PREMIUM")
      .then((response) => {
        // console.log("RESPPONSE.getReviews.PREMIUM", response.data);
        setPremiumReviews(getMappedReview(response.data).filter(({featured}) => !featured));
      })
      .catch((reason) => console.log("ERROR WITH REASON.getReviews:", reason));
    getReviews("FREEMIUM")
      .then((response) => {
        // console.log("RESPPONSE.getReviews.FREEMIUM", response.data);
        setFreemiumReviews(getMappedReview(response.data).filter(({featured}) => !featured));
      })
      .catch((reason) => console.log("ERROR WITH REASON.getReviews:", reason));
  }, []);

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h1 className="font-semibold text-slate-800">Reviews</h1>
      </header>
      
        <h2 style={{marginLeft: 15}} className="font-semibold text-slate-800">
          Featured{" "}
          {featuredReviews && featuredReviews.length == 0 ?  <Spinner style={{margin: 15}} animation="border" variant="primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner> : ""}
        </h2>
        {featuredReviews &&
          featuredReviews.map((review) => (
            <tr>
              
              <td>
                <ReviewCard review={review} />
              </td>
              <td>
                <Switch
                  value={
                    featuredReviews.filter(({ _id }) => _id == review._id)
                      .length > 0
                      ? "0"
                      : "1"
                  }
                  on="0"
                  off="1"
                  onChange={() => {
                    updateReviewState(
                      review._id,
                      "featured",
                      false
                    ).then((response) =>
                      console.log("RESPONSECHUNKADD:", response)
                    );
                    setFeaturedReviews(featuredReviews.filter(({_id})=>review._id!=_id));
                    switch(review.userType){
                      case 'PLATINUM':
                        setPlatinumReviews([...platinumReviews, {...review, featured: false}]);
                        break;
                      case 'PREMIUM':
                        setPremiumReviews([...premiumReviews, {...review, featured: false}]);
                        break;
                      case 'FREEMIUM':
                        setFreemiumReviews([...freemiumReviews, {...review, featured: false}]);
                        break;
                      default:
                    }
                  }}
                />
              </td>
            </tr>
          ))}
        <br />
        <br />
        <br />
        <h2 style={{marginLeft: 15}} className="font-semibold text-slate-800">
          Platinum{" "}
          {platinumReviews && platinumReviews.length == 0 ?  <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner> : ""}
        </h2>
        {platinumReviews &&
          platinumReviews.map((review) => (
            <tr>
              <td> </td>
              <td>
                <ReviewCard review={review} />
              </td>
              <td style={{marginRight: '20px'}}>
                <Switch
                  value="1"
                  on="0"
                  off="1"
                  onChange={() => {
                    updateReviewState(
                      review._id,
                      "featured",
                      true
                    ).then((response) =>
                      console.log("RESPONSECHUNKADD:", response)
                    );
                    setFeaturedReviews([...featuredReviews, {...review, featured: true}]);
                    setPlatinumReviews(platinumReviews.filter(({_id})=>review._id!=_id));
                  }}
                />
              </td>
            </tr>
          ))}
        <h2 style={{marginLeft: 15}} className="font-semibold text-slate-800">
          Premium{" "}
          {premiumReviews && premiumReviews.length == 0 ?  <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner> : ""}
        </h2>
        {premiumReviews &&
          premiumReviews.map((review) => (
            <tr>
              <td> </td>
              <td>
                <ReviewCard review={review} />
              </td>
              <td>
                <Switch
                  value="1"
                  on="0"
                  off="1"
                  onChange={() => {
                    updateReviewState(
                      review._id,
                      "featured",
                      true
                    ).then((response) =>
                      console.log("RESPONSECHUNKADD:", response)
                    );
                    setFeaturedReviews([...featuredReviews, {...review, featured: true}]);
                    setPremiumReviews(premiumReviews.filter(({_id})=>review._id!=_id));
                  }}
                />
              </td>
            </tr>
          ))}
        <h2 style={{marginLeft: 15}} className="font-semibold text-slate-800">
          Freemium{" "}
          {freemiumReviews && freemiumReviews.length == 0 ?  <Spinner  animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner> : ""}
        </h2>
        {freemiumReviews &&
          freemiumReviews.map((review) => (
            <tr>
              <td> </td>
              <td>
                <ReviewCard review={review} />
              </td>
              <td style={{marginRight: 20}}>
                <Switch
                  value="1"
                  on="0"
                  off="1"
                  onChange={() => {
                    updateReviewState(
                      review._id,
                      "featured",
                      true
                    ).then((response) =>
                      console.log("RESPONSECHUNKADD:", response)
                    );
                    setFeaturedReviews([...featuredReviews, {...review, featured: true}]);
                    setFreemiumReviews(freemiumReviews.filter(({_id})=>review._id!=_id));
                  }}
                />
              </td>
            </tr>
          ))}


      
    </div>
  );
}

export default DashboardCard33;
