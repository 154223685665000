import React,{useState,useEffect}  from 'react';
import axios from "axios";
import {baseUrl,access_token} from '../../services/api';
import SearchIcon from '@mui/icons-material/Search';
import AppString from '../components/AppStiring';
function BookTalk04() {
  const[searchVal,setSearchVal]=useState();

 const[ customers,setCustomers]=useState([])
 const[ refresh,setReferash]=useState(true)

 const[ page,setPage]=useState(1)
 const[ buttonText,setButtonText]=useState("See More")
 const[ searchedUser,setSearchedUser]=useState([])

  useEffect(()=>{

    try{

  
          axios
          .get(`${baseUrl}/analytics/getTimeSpentOnTalksGroupedByBookId/${page}`,{  headers: {
            'Authorization': `Bearer ${access_token}`
          }})
          
          .then((response) => {
            if (response.data.timeSpentOnTalksGroupedByBookId.length==0) {
              setButtonText("See Less")
              customers=customers.slice(0, 10);
             
              setPage(1)
              setReferash(false)
            }else{
              setCustomers(response.data.timeSpentOnTalksGroupedByBookId)
      
          }}
          
          )     
          .catch(err => {
            console.error(err);
            
          });
         
          
  
    } catch (error) {
      if(error.message === 'Network Error'){
          alert('no internet connection');
      }
      
}},[page]

)
const handleLoadMore=()=>{
  if (buttonText=="See Less") {
    setReferash(false)
  }
  setPage(customers.length<10?page:page+1)

}
const handleLoadLess=()=>{
  if (buttonText=="See Less") {
    setReferash(false)
  }
  setPage(page<=1?1:page-1)

}
const handleClick = (event, param) => {



  try{

  
    axios
    .patch(`${baseUrl}/admin/adminUpdate/${param.id}`,{
      "userStatus":`${param.value=="BANNED"?"UNBANNED":"BANNED"}`
  },{  headers: {'Content-Type': 'application/json',
      'Authorization': `Bearer ${access_token}`
    },
  })
    
    .then((response) => {
     alert(`USER ${param.value=="BANNED"?"UNBANNED":"BANNED"} SUCCESSFULLY!`)

    }
    
    )     
    .catch(err => {
      console.error(err);
      
    });
   
    

} catch (error) {
if(error.message === 'Network Error'){
    alert('no internet connection');
}
}
};

const handleSearch = (event, param) => {

  if (!param||param.length==0) {
  }else{
  try{
    axios
    .get(`${baseUrl}/admin/getUserByEmailOrPhoneNumber/${param}`,{  headers: {
      'Authorization': `Bearer ${access_token}`
    },
  })
    
    .then((response) => {
      setSearchedUser(response.data)
      // setRefresh(!refresh)

    }
    
    )     
    .catch(err => {
      console.error(err);
      
    });
   
    

} catch (error) {
if(error.message === 'Network Error'){
    alert('no internet connection');
}
}}
  console.log(event);
  console.log(param);
};
function handleChange(event) {
  console.log(event.target.value);
  setSearchVal(event.target.value)
  handleSearch(event, event.target.value);
}
  return (
    // <div>
    //   {
    //   customers.map((customer,)=>
    //   JSON.stringify(customer.user.userInfo.userProfileUrl===""?'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png':customer.user.userInfo.userProfileUrl)
    // //   ||customer.user.userInfo.userProfileUrl===""?'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png':customer.userInfo.userProfileUrl
      
    //   )
        
    //   }  
    // </div>
    <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100" >
        <h2 className="font-semibold text-slate-800" style={{width:'50%',display:'inline-block'}}>{"Most Time spent on  Talks grouped by books"}</h2>

        {/* <input placeholder='Search' style={{float:"right",borderRadius:"10px",paddingRight:"5px",paddingLeft:"5px",border:"solid"}} onChange={handleChange}/>
        <SearchIcon style={{float:"right",margin:"5px"}} onClick={event => handleSearch(event, searchVal)}/>
       */}
      </header>
      <div className="p-3">

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-400 bg-slate-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Title</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Author</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Time Spent on Talks grouped by book </div>
                </th>
                {/* <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Status</div>
                </th> */}
                {/* <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Total number of report</div>
                </th> */}
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-100">
              {searchedUser.length==0?
                customers.map(customer => {
                  return (
                    <tr key={customer._id}>
                      <td className="p-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
                            <img className="rounded-full" style={{objectFit:'cover',width:"40px", height:"40px"}}   src={!customer.book||customer.book.imageUrl===""?'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png':customer.book.imageUrl}  
                                alt={customer.book.title} />
                          </div>
                          <div className="font-medium text-slate-800"><AppString>{customer.book.title}</AppString>  </div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{customer.book.author}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium text-green-500 ">{customer.total}</div>
                      </td>
                      {/* <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium text-green-500 " onClick={event => handleClick(event, {"id":customer.user._id,"value":customer.user.userStatus})}><button >{customer.user.userStatus=="BANNED"?"Unban user":"Ban user"}</button></div>
                      </td> */}
                      {/* <td className="p-2 whitespace-nowrap">
                        <div className="text-lg text-center">{customer.totalReportnumber}</div>
                      </td> */}
                    </tr>
                  )
                }):  searchedUser.map(customer => {
                  return (
                    <tr key={customer._id}>
                      <td className="p-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
                            <img className="rounded-full" style={{objectFit:'cover',width:"40px", height:"40px"}}  src={!customer.book||customer.book.imageUrl===""?'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png':customer.book.imageUrl}  alt={customer.book.imageUrl} />
                          </div>
                          <div className="font-medium text-slate-800">{customer.book.title}</div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{customer.book.author}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium text-green-500 ">{customer.total}</div>
                      </td>
                      {/* <td className="p-2 whitespace-nowrap">
                        <div className="text-lg text-center">{customer.totalReportnumber}</div>
                      </td> */}
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <div style={{width:"100%",textAlign:"right"}}>
{  page>1&&        <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white" onClick={handleLoadLess}>{"Previous"}</button>
}
       {customers.length>=10&&       <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white" style={{textAlign:"right",margin:"10px"}} onClick={handleLoadMore}>{"Next"}</button>
        }</div>
        </div>

      </div>
    </div>
  );
}

export default BookTalk04;
