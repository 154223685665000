import React ,{useState,useEffect}from 'react';
import Info from '../../utils/Info';
import BarChart from '../../charts/BarChart02';
import axios from "axios";
import {baseUrl,access_token} from '../../services/api';
// Import utilities
import { tailwindConfig } from '../../utils/Utils';

function DashboardCard09() {
  const[data,setData]=useState([])
  const chartData = {
    labels: [
      'Users', 'Books', 'bookcircle',
      'Reported and Banned Users'
    ],
    datasets: [

      {
        label: 'Stack 2',
        data:data,
        backgroundColor: tailwindConfig().theme.colors.indigo[200],
        hoverBackgroundColor: tailwindConfig().theme.colors.indigo[300],
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
    ],
  };
  useEffect(()=>{

    try{

          
      axios
      .get(`${baseUrl}/analytics/getAggregatedValuesAnalytics`,{  headers: {
        'Authorization': `Bearer ${access_token}`
      }})
      
      .then((response) => {

       const arr=[]
       arr.push(response.data.user)
       arr.push(response.data.books)
       arr.push(response.data.bookcircle)
       arr.push(response.data.report)
       setData(arr)
        
      }
      
      )     
      .catch(err => {
        console.error(err);
        
      }); 
    } catch (error) {
      if(error.message === 'Network Error'){
          alert('no internet connection');
      }
}},[])


  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100 flex items-center">
        <h2 className="font-semibold text-slate-800">All</h2>
        <Info className="ml-2" containerClassName="min-w-80">
          <div className="text-sm">Total Number of users, Total Number of books , Total Number of book Talks and Total Number of reported users </div>
        </Info>
      </header>
      <div className="px-5 py-3">
        <div className="flex items-start">
          {/* <div className="text-3xl font-bold text-slate-800 mr-2">+$6,796</div> */}
          {/* <div className="text-sm font-semibold text-white px-1.5 bg-yellow-500 rounded-full">-34%</div> */}
        </div>
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="grow">
        {/* Change the height attribute to adjust the chart height */}
        <BarChart data={chartData} width={595} height={248}  datas={data}/>
      </div>
    </div>
  );
}

export default DashboardCard09;
