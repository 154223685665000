import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import axios from "axios";
import { baseUrl, access_token } from '../../services/api';
// Import utilities
import { tailwindConfig } from '../../utils/Utils';
import { LinearProgress } from '@mui/material';

function DashboardCard16({ totalUser }) {

  const [userOnline, setUserOnline] = useState(0);
  const [userOffline, setUserOffline] = useState(0);

  const chartData = {
    labels: ['Online', 'Offline'],
    datasets: [
      {
        label: 'Number of Users',
        data: [userOnline, userOffline],
        backgroundColor: [
          'rgba(34, 197, 94, 0.7)',  // Green for Online
          'rgba(239, 68, 68, 0.7)',  // Red for Offline
        ],
        hoverBackgroundColor: [
          'rgba(34, 197, 94, 0.9)',  // Darker Green on Hover
          'rgba(239, 68, 68, 0.9)',  // Darker Red on Hover
        ],
        borderColor: tailwindConfig().theme.colors.white,
        borderWidth: 2,
      },
    ],
  };

  useEffect(() => {
    let interval = setInterval(() => {
      try {
        axios
          .get(`${baseUrl}/analytics/onlineUsers`, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          })
          .then((response) => {
            if (response.data[0]) {
              setUserOnline(response.data.length);
              setUserOffline(totalUser === 0 ? userOnline : totalUser - userOnline);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (error) {
        if (error.message === 'Network Error') {
          alert('No internet connection');
        }
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [totalUser, userOnline]);

  return (
    <div style={{borderRadius: '15px'}} className="flex flex-col col-span-full sm:col-span-6 xl:col-span-6 bg-white shadow-lg rounded-xlg border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800">User Active Status</h2>
      </header>
      {/* Chart */}
      <div className="p-5">
        {userOffline === 0 && userOnline === 0 ? (
          // <LinearProgress />
          <></>
        ) : (
          <Pie data={chartData} width={200} height={200} />
        )}
      </div>
    </div>
  );
}

export default DashboardCard16;
