import React, { useState, useEffect } from "react";
import {  Form } from 'react-bootstrap';
import "../../css/login.css";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useLocation, useNavigate} from "react-router-dom"

import axios from "axios";
import {baseUrl,access_token} from '../../services/api';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import firebase from "../../services/firebaseConfig";
import "firebase/compat/storage";
import Switch from 'react-input-switch';
// import Palette from 'react-palette';
import { usePalette } from 'react-palette'
// var im = require('imagemagick');
export default function AddBookForm() {
   const storage = firebase.storage();
   const navigate = useNavigate();
  const location = useLocation()
  const { isbns, authors, titles, descriptions, subtitles, imageUrls, genres, publishers, publishedDates, _ids, languages, bookAddedAS } = location.state || {};
  const {newbook} = location.state;
  const { data, loading, error } = usePalette(imageUrls)

  const [title, setTitle] = useState(titles)
  const [featuredBooks, setFeaturedBooks] = useState()
  const [featured, setFeatured] = useState(1);
  const [recommended, setRecommend] = useState(1);
  const [author, setAuthor] = useState(authors);
  const [imageUrl, setImageUrl] = useState();
  const [audioUrl, setAudioUrl] = useState();

  const [description, setDescription] = useState(descriptions);
  const [publisher, setPublisher] = useState(publishers);
  const [publishedDate, setPublishedDate] = useState(publishedDates);
  const [bookCoverColor, setBookCover] = useState()
  const [subTitle, setSubTitile] = useState(subtitles);
  const [genre, setGenre] = useState(genres);
  const [language, setLanguage] = useState(languages);
  const [imageAsFile, setImageAsFile] = useState()
  const [audioAsFile, setAudioAsFile] = useState()
  const [imageAsUrl, setImageAsUrl] = useState()

    const [audioAsUrl, setAudioAsUrl] = useState()
    const [bookData, setBookData] = useState({
      ...(newbook === "1" ? {} : { _id: _ids }),
      isbn: newbook === "1" ? '' : isbns,
      imageUrl: '',
      title: '',
      genre: '',
      subTitle: '',
      publisher: '',
      publishedDate: '',
      description: '',
      bookCoverColor: bookCoverColor,
      language: '',
      author: '',
      // bookAddedAS: featured === 0 ? null : "FEATURED_BOOK"
    });

    useEffect(() => {

      const fetchBookData = async () => {
        try {
          const bookInDB = await axios.get(`${baseUrl}/admin/books/${isbns}`, {
            headers: {
              'Authorization': `Bearer ${access_token}`
            }
          });
    
          if (bookInDB.data) {
            // Book exists, set the retrieved data in the state
            console.log("Book is Exist",bookInDB.data)
            setBookData(bookInDB.data);
            setFeatured(bookInDB.data.bookAddedAS.includes('FEATURED_BOOK') ? 0 : 1);
            setRecommend(bookInDB.data.bookAddedAS.includes('RECOMMENDED_BOOK') ? 0 : 1);
          } else {
            // Book doesn't exist, add it to the database first
            console.log("Book doesn't exist")
            const newBookResponse =  await  axios
            .post(`${baseUrl}/admin/books`,
              {
                isbn: isbns,
                imageUrl:  imageUrls,
                title:  titles,
                genre: genres,// Use the value from location.state if available
                subTitle: subtitles,
                publisher: publishers,
                publishedDate: publishedDates,
                description: descriptions,
                bookCoverColor: bookCoverColor,
                language: languages,
                author: authors 
              },
              {
                headers: {
                  'Authorization': `Bearer ${access_token}`
                }
              }
            );
    
            if (newBookResponse.data) {
              // Fetch the newly added book data
              console.log("Book enterd to database sucssfully ")
              setBookData(newBookResponse.data);
              setFeatured(newBookResponse.data.bookAddedAS.includes('FEATURED_BOOK') ? 0 : 1);
              setRecommend(newBookResponse.data.bookAddedAS.includes('RECOMMENDED_BOOK') ? 0 : 1);
            } else {
              console.error('Error adding book to the database:', newBookResponse.statusText);
            }
          }
        } catch (error) {
          console.error('Error fetching book data:', error);
        }
      };
    
      if(newbook !== "1"){
        fetchBookData();
      }else{
      console.log("  this is new book")
      }
     
    }, [baseUrl, isbns, access_token, location.state]);
    


  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    if (image && image.size > 60 * 1024) {
      alert('Please select an image with a size below 60KB.');
      return; // Don't proceed if the image size is too large
    }
    setBookData((prevData) => ({ ...prevData, imageUrl: '' }));
    setImageAsFile(image);
    uploadImage(image);
  };
  
  
const uploadImage = async (image) => {
  try {
    // await handleUpload(image);


    setBookData((prevData) => ({ ...prevData, imageUrl: '' })); // Set imageUrl to an empty string

    // Your code for getting the download URL directly from Firebase Storage
    var user = firebase.auth().currentUser;
    if(user!=null){
    const path = `/featuredBooksImage/${image.name}`;
    const ref = storage.ref().child(path);
    await ref.put(image);
    const url = await ref.getDownloadURL();

    // Update the imageUrl in the bookData and in the state
    setBookData((prevData) => ({ ...prevData, imageUrl: url }));
    setImageAsUrl(url);
    }
  } catch (error) {
    console.error('Image upload failed:', error);
  }
};

  
  // const handleImageUpload = async (image) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => resolve(reader.result);
  //     reader.onerror = reject;
  //     reader.readAsDataURL(image);
  //   });
  // };
  
  // async  function handleUpload (e) {
  //   console.log(e);
  //   var user = firebase.auth().currentUser;
  //   if(user!=null){
  //     console.log("ffi",firebase,storage,imageAsFile);
  //     e.preventDefault();
  //     const path = `/featuredBooksImage/${imageAsFile.name}`;
  //     console.log("Path ..... ....",path);
  //     const ref = storage.ref().child(path);
  //     console.log("refrenace .. ",ref);
  //     await ref.put(imageAsFile);
  //     console.log("kkkjjjj");
  //     const url = await ref.getDownloadURL();
  //     console.log("urlllll",url);
  //     setImageAsUrl(url);
  //     setImageAsFile(null);
  //   }
  // }
  
  
  
 
async  function handleAudioUpload (e) {
  console.log(e);
  var user = firebase.auth().currentUser;
  if(user!=null){
    console.log("ffi",firebase,storage,audioAsFile);
    e.preventDefault();
    const path = `/featuredBooksAudio/${audioAsFile.name}`;
    console.log(path);
    const ref = storage.ref().child(path);
    console.log(ref);
    
    await ref.put(audioAsFile);
    console.log("kkkjjjj");
    const url = await ref.getDownloadURL();
    console.log("urlllll",url);
    setAudioAsUrl(url);
    setAudioAsFile(null);
  }
  
  }
  const handleAudioAsFile = (e) => {
    const audio = e.target.files[0]
    setAudioAsFile(audio)
}
  function validateForm() {
  }
  // if(email != "admin@123.com" && password != 'admin'){
  //     useEffect(
  //     setError("password and user name incorrect ")
  //     );
  //   }

  const handleUpdate = async () => {
    console.log('theDATA',bookData);
    setOpen(false);
    if(bookData){
      try {
        // Make a PATCH request to update the book data
        const response = await fetch(`${baseUrl}/admin/updateBooks/${bookData._id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
          },
          body: JSON.stringify(bookData),
        });
  
        if (response.ok) {
          alert('Book updated successfully!');
          console.log('Book updated successfully!');
          navigate('/addFeaturedBook');
          // Handle success, e.g., show a success message or redirect
        } else {
          alert('Failed to update book:');
          console.error('Failed to update book:', response.status, response.statusText);
          // Handle failure, e.g., show an error message
        }
      } catch (error) {
        alert('Error updating book:');
        console.error('Error updating book:', error);
      }
    }else{
      console.log("Update books from search");
      try{

    await  axios
      .post(`${baseUrl}/admin/books`,{
        
          "isbn": isbns,
         "imageUrl": imageAsUrl,
         "title": title,
         "genre":genre,
        "subTitle":subtitles,
        "publisher": publisher,
        "publishedDate": publishedDate,
        "description": description,
        "bookCoverColor": bookCoverColor,
        "language": languages,
         "author": authors,
        "bookAddedAS": (featured==0&&recommended==0)?null:(featured!=0&&recommended!=0)?"FEATURED_BOOK_RECOMMENDED_BOOK":recommended==0?"FEATURED_BOOK":"RECOMMENDED_BOOK"
      },
      
      {  headers: {
        'Authorization': `Bearer ${access_token}`
      }})
      
      .then((response) => {
          alert(response.data.message)
          }

      )     
      .catch(err => {
        console.error(err);
        
      });
     
      
    
} catch (error) {
  if(error.message === 'Network Error'){
      alert('no internet connection');
  }}
  
    }
  
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setBookData((prevData) => ({ ...prevData, [name]: value }));
  };
  const setFeatured1 = (value) => {
    console.log("sdfghjklkjhg", featured);
    setFeatured(value === '1' ? 1 : 0);
    setBookData((prevData) => ({
      ...prevData,
      bookAddedAS: value === '1' ? null:!recommended?"FEATURED_BOOK_RECOMMENDED_BOOK": 'FEATURED_BOOK',
    }));
  };
  const setRecommended = (value) => {
    console.log("sdfghjklkjhg", recommended);
    setRecommend(value === '1' ? 1 : 0);
    setBookData((prevData) => ({
      ...prevData,
      bookAddedAS: value === '1' ? null: !featured?"FEATURED_BOOK_RECOMMENDED_BOOK":'RECOMMENDED_BOOK',
    }));
  };

  async function handleaddnew(){
    setOpen(false);
      console.log("DaTAAAA DASSSAA ", bookData)
    try {
      // Send a POST request to the server using axios
      const response = await axios
      .post(`${baseUrl}/admin/books`,
               bookData
              , {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access_token}`, // Add your authorization header
        },
      });
  
      // Handle success
      console.log('Book submitted successfully:', response.data);
      alert('Book submitted successfully');
      navigate('/addFeaturedBook');
    } catch (error) {
      // Handle error
      console.error('Error submitting book data:', error);
      alert('Failed to submit book data. Please try again.');
    }
   
  }
 async function handleEdit() {
      const bookInDB= await axios.get(`${baseUrl}/admin/books/${isbns}`,{
        headers: {
          'Authorization': `Bearer ${access_token}`
        }
      })
      // console.log(bookInDB.data);

        if (bookInDB.data) {
          console.log("Update");
          try{

         await   axios
            .patch(`${baseUrl}/admin/updateBooks/${bookInDB.data._id}`,{
              _id:_ids,
              isbn: isbns,
              imageUrl: imageAsUrl,
              title: title,
              genre:genre?genre:"",
             subTitle:subTitle,
             publisher: publishers?publishers:"",
             publishedDate: publishedDate?publishedDate:"",
             description: description,
             bookCoverColor: bookCoverColor,
             language: language,
            author: author,
             bookAddedAS: (featured==0&&recommended==0)?null:(featured!=0&&recommended!=0)?"FEATURED_BOOK_RECOMMENDED_BOOK":recommended==0?"FEATURED_BOOK":"RECOMMENDED_BOOK"
            },
            
            {  headers: {
              'Authorization': `Bearer ${access_token}`
            }})
            
            .then((response) => {
              console.log(response);
                alert(response.data.message)
                }
    
            )     
            .catch(err => {
              console.error(err);
              
            });
           
            
    
      } catch (error) {
        if(error.message === 'Network Error'){
            alert('no internet connection');
        }
        
    }
        }
        else{
          console.log("Update");
        try{

      await  axios
        .post(`${baseUrl}/admin/books`,{
          
            "isbn": isbns,
           "imageUrl": imageAsUrl,
           "title": title,
           "genre":genre,
          "subTitle":subtitles,
          "publisher": publisher,
          "publishedDate": publishedDate,
          "description": description,
          "bookCoverColor": bookCoverColor,
          "language": language,
           "author": authors,
          "bookAddedAS": featured==0?null:"FEATURED_BOOK"
        
        },
        
        {  headers: {
          'Authorization': `Bearer ${access_token}`
        }})
        
        .then((response) => {
            alert(response.data.message)
            }

        )     
        .catch(err => {
          console.error(err);
          
        });
       
        
      
  } catch (error) {
    if(error.message === 'Network Error'){
        alert('no internet connection');
    }}
    
}
setOpen(false);

     
  }

  return (
  <div className="maincontainer">
    <div class="container-fluid">
        <div class="row no-gutter">
           
            <div class="col-md-12 ">
              <div class="col-md-9  ">

             
                <div class="login d-flex align-items-center py-5">
                   
                    <div class="container">
                        <div class="row">
                        
{newbook == "1"?(


<>

                  <div class="col-lg-10 col-xl-7 mx-auto">
                                <center><h1 class="display-4" style={{color:"black"}}>Add New book</h1></center>
                                <form>
                                    <div class="form-group mb-3">
                                        <label  for="isbn">Book isbn</label>
                                      
                                    <input id="isbn" 
                                         type="text" placeholder="Enter Isbn for the book" name="isbn" required=""  autoFocus
                                         value={bookData.isbn}
                                         onChange={handleChange}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />


                                      </div>
                                    <div class="form-group mb-3">
                                        <label for="title">Book Title:</label>
                                        <input id="title"
                                         type="text" placeholder="Enter title for the book" required="" name="title" autoFocus
                                         value={bookData.title}
                                         onChange={handleChange}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="Genre">Book Genre:</label>
                                        <input id="Genre"
                                         type="text" placeholder="Enter gener for the book" required="" name="genre" autoFocus
                                         value={bookData.genre}
                                         onChange={handleChange}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="subTitle">Book subTitle:</label>
                                        <input id="subTitle"
                                         type="text" placeholder="Enter subtitle for the book" required="" name="subTitle" autoFocus
                                         value={bookData.subTitle}
                                         onChange={handleChange}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="publisher">Book publisher</label>
                                        <input id="publisher"
                                         type="text" placeholder="Enter publisher for the book" required="" name="publisher" autoFocus
                                         value={bookData.publisher}
                                         onChange={handleChange}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="publishedDate">Book publishedDate</label>
                                        <input id="publishedDate"
                                         type="text" placeholder="Enter publish date for the book" name="publishedDate"  autoFocus
                                         value={bookData.publishedDate}
                                         onChange={handleChange}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="description">Book description:</label>
                                        <input id="description"
                                         type="text" placeholder="Enter desription for the book" required="" name="description"  autoFocus
                                         value={bookData.description}
                                         onChange={handleChange}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>

                                    <div class="form-group mb-3">
                                        <label for="language">Book language:</label>
                                        <input id="language"
                                         type="text" placeholder="Enter language for the book" name="language" required=""  autoFocus
                                         value={bookData.language}
                                         onChange={handleChange}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="author">Book author</label>
                                        <input id="author"
                                         type="text" placeholder="Enter author for the book" required="" name="author" autoFocus
                                         value={bookData.author}
                                         onChange={handleChange}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    <div className="form-group mb-3">
  <label htmlFor="imageUrl">Book image Replace</label>
  {bookData.imageUrl && bookData.imageUrl !== '' && (
    <div style={{ width: '150px', height: '130px', margin: '10px' }}>
      <img src={bookData.imageUrl} style={{ width: '100px', height: '120px' }} alt="Book Cover" />
    </div>
  )}
  
  <div style={{ margin: '10px' }}>
    <input
      id="imageUrl"
      type="file"
      placeholder={imageUrls}
      name="imageUrl"
      required=""
      autoFocus
      onChange={handleImageAsFile}
      className="form-control rounded-pill border-0 shadow-sm px-4"
    />
  </div>
  {/* <button style={{backgroundColor:"blue",color:"white",padding:"8px"}} onClick={handleUpload}>upload</button> */}
</div>


                                  <div class="form-group mb-3">
                                        <label for="imageUrl">Book Audio Replace</label>
                    
                                         <div style={{margin:"10px"}}>
                  
                                     <input id="audioUrl"
                                         type="file" placeholder={audioUrl} required=""  autoFocus
                                         value={audioUrl}
                                        onChange={handleAudioAsFile}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    
                                         </div>
                                  </div>
                                  <button style={{backgroundColor:"blue",color:"white",padding:"8px"}} onClick={handleAudioUpload}>upload</button>
                                    <div class="form-group mb-3">
                                    </div>
                                    {/* <div class="custom-control custom-checkbox mb-3">
                                        <input id="customCheck1" type="checkbox" checked class="custom-control-input" />
                                        <label for="customCheck1" class="custom-control-label">Remember password</label>
                                    </div> */}

                                    <div>
      <div  class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill" style={{backgroundColor:"blue"}} onClick={handleClickOpen} >{newbook=="1"?"Add New Book":"Submit"}</div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to add new Book?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>No</Button>
          <Button onClick={handleaddnew} >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
                                    {/* <div class="text-center d-flex justify-content-between mt-4"><p>Code by <a href="#" class="font-italic text-muted"> 
                                            <u>Jassa</u></a></p></div> */}
                                </form>
                            </div>
</>
):(


<div class="col-lg-10 col-xl-7 mx-auto">
<center><h1 class="display-4" style={{color:"black"}}>Add {newbook=="1"?"New":"Featured"} book</h1></center>
<form>
    <div class="form-group mb-3">
        <label  for="isbn">Book isbn: {newbook=="1"?" ":isbns}</label>
        {newbook=="1"?(
    <input id="isbn" 
         type="text" placeholder={"Enter Isbn for the book"} required=""  autoFocus
         value={bookData.isbn}
        
         class="form-control rounded-pill border-0 shadow-sm px-4" />

        ):""}
      </div>
    <div class="form-group mb-3">
        <label for="title">Book Title:</label>
        <input id="title"
         type="text" placeholder={titles} required="" name="title" autoFocus
         value={bookData.title}
         onChange={handleChange}
         class="form-control rounded-pill border-0 shadow-sm px-4" />
    </div>
    <div class="form-group mb-3">
        <label for="Genre">Book Genre:</label>
        <input id="Genre"
         type="text" placeholder={genres} required="" name="genre" autoFocus
         value={bookData.genre}
         onChange={handleChange}
         class="form-control rounded-pill border-0 shadow-sm px-4" />
    </div>
    <div class="form-group mb-3">
        <label for="subTitle">Book subTitle:</label>
        <input id="subTitle"
         type="text" placeholder={subtitles} required="" name="subTitle" autoFocus
         value={bookData.subTitle}
         onChange={handleChange}
         class="form-control rounded-pill border-0 shadow-sm px-4" />
    </div>
    <div class="form-group mb-3">
        <label for="publisher">Book publisher</label>
        <input id="publisher"
         type="text" placeholder={publishers} required="" name="publisher" autoFocus
         value={bookData.publisher}
         onChange={handleChange}
         class="form-control rounded-pill border-0 shadow-sm px-4" />
    </div>
    <div class="form-group mb-3">
        <label for="publishedDate">Book publishedDate</label>
        <input id="publishedDate"
         type="text" placeholder={publishedDates} name="publishedDate"  autoFocus
         value={bookData.publishedDate}
         onChange={handleChange}
         class="form-control rounded-pill border-0 shadow-sm px-4" />
    </div>
    <div class="form-group mb-3">
        <label for="description">Book description:</label>
        <input id="description"
         type="text" placeholder={descriptions} required="" name="description"  autoFocus
         value={bookData.description}
         onChange={handleChange}
         class="form-control rounded-pill border-0 shadow-sm px-4" />
    </div>

    <div class="form-group mb-3">
        <label for="language">Book language:</label>
        <input id="language"
         type="text" placeholder={languages} name="language" required=""  autoFocus
         value={bookData.language}
         onChange={handleChange}
         class="form-control rounded-pill border-0 shadow-sm px-4" />
    </div>
    <div class="form-group mb-3">
        <label for="author">Book author</label>
        <input id="author"
         type="text" placeholder={author} required="" name="author" autoFocus
         value={bookData.author}
         onChange={handleChange}
         class="form-control rounded-pill border-0 shadow-sm px-4" />
    </div>
    <div className="form-group mb-3">
<label htmlFor="imageUrl">Book image Replace</label>
{bookData.imageUrl && bookData.imageUrl !== '' && (
<div style={{ width: '150px', height: '130px', margin: '10px' }}>
<img src={bookData.imageUrl} style={{ width: '100px', height: '120px' }} alt="Book Cover" />
</div>
)}

<div style={{ margin: '10px' }}>
<input
id="imageUrl"
type="file"
placeholder={imageUrls}
name="imageUrl"
required=""
autoFocus
onChange={handleImageAsFile}
className="form-control rounded-pill border-0 shadow-sm px-4"
/>
</div>
{/* <button style={{backgroundColor:"blue",color:"white",padding:"8px"}} onClick={handleUpload}>upload</button> */}
</div>


  <div class="form-group mb-3">
        <label for="imageUrl">Book Audio Replace</label>

         <div style={{margin:"10px"}}>

     <input id="audioUrl"
         type="file" placeholder={audioUrl} required=""  autoFocus
         value={audioUrl}
        onChange={handleAudioAsFile}
         class="form-control rounded-pill border-0 shadow-sm px-4" />
    
         </div>
  </div>
  <button style={{backgroundColor:"blue",color:"white",padding:"8px"}} onClick={handleAudioUpload}>upload</button>
    <div class="form-group mb-3">
    </div>
    {/* <div class="custom-control custom-checkbox mb-3">
        <input id="customCheck1" type="checkbox" checked class="custom-control-input" />
        <label for="customCheck1" class="custom-control-label">Remember password</label>
    </div> */}

    <div>
<div  class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill" style={{backgroundColor:"blue"}} onClick={handleClickOpen} >{newbook=="1"?"Add New Book":"Submit"}</div>
<Dialog
open={open}
onClose={handleClose}
aria-labelledby="alert-dialog-title"
aria-describedby="alert-dialog-description"
>

<DialogContent>
<DialogContentText id="alert-dialog-description">
Do you want to Edit Book Information?
</DialogContentText>
</DialogContent>
<DialogActions>
<Button onClick={handleClose} autoFocus>No</Button>
<Button onClick={handleUpdate} >
Yes
</Button>
</DialogActions>
</Dialog>
</div>
    {/* <div class="text-center d-flex justify-content-between mt-4"><p>Code by <a href="#" class="font-italic text-muted"> 
            <u>Jassa</u></a></p></div> */}
</form>
</div>

)}

                            
                        </div>
                    </div>
                </div> 
                
            </div>
            </div>
        </div>
    </div>
  </div>

  
  );
 
}