import React, { useState,useEffect } from 'react';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import WelcomeBanner from '../partials/dashboard/WelcomeBanner';
import DashboardAvatars from '../partials/dashboard/DashboardAvatars';
import FilterButton from '../partials/actions/FilterButton';
import Datepicker from '../partials/actions/Datepicker';
import DashboardCard01 from '../partials/dashboard/DashboardCard01';
import DashboardCard02 from '../partials/dashboard/DashboardCard02';
import DashboardCard03 from '../partials/dashboard/DashboardCard03';
import DashboardCard04 from '../partials/dashboard/DashboardCard04';
import DashboardCard05 from '../partials/dashboard/DashboardCard05';
import DashboardCard06 from '../partials/dashboard/DashboardCard06';
import DashboardCard07 from '../partials/dashboard/DashboardCard07';
import DashboardCard08 from '../partials/dashboard/DashboardCard08';
import DashboardCard09 from '../partials/dashboard/DashboardCard09';
import DashboardCard10 from '../partials/dashboard/DashboardCard10';
import DashboardCard20 from '../partials/dashboard/DashboardCard20';
import DashboardCard30 from '../partials/dashboard/DashboardCard30';

import DashboardCard11 from '../partials/dashboard/DashboardCard11';
import DashboardCard12 from '../partials/dashboard/DashboardCard12';
import DashboardCard13 from '../partials/dashboard/DashboardCard13';
import Banner from '../partials/Banner';
import DashboardCard14 from '../partials/dashboard/DashboardCard14';
import DashboardCard15 from '../partials/dashboard/DashboardCard15';
import DashboardCard16 from '../partials/dashboard/DashboardCard16';
import axios from "axios";
import {baseUrl,access_token} from '../services/api';
import DashboardCard17 from '../partials/dashboard/DashboardCard17';
import DashboardCard18 from '../partials/dashboard/DashboardCard18';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import GroupIcon from '@mui/icons-material/Group';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import DashboardCard31 from '../partials/dashboard/DashboardCard31';
import DetailsForReviews from '../partials/Details/DetailsForReviews';
function Details({id}) {
  const[refresh,setRefresh]=useState(true)

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [totalUser, setTotal] = useState(0);
  const[user,setUser]=useState(0)
  const[book,setBook]=useState(0)
  const[bookTalk,setBookTalk]=useState(0)
  const[bannedUser,setBannedUser]=useState(0)
  const[searchVal,setSearchVal]=useState();

useEffect(()=>{

  try{

        
    axios
    .get(`${baseUrl}/analytics/getAggregatedValuesAnalytics`,{  headers: {
      'Authorization': `Bearer ${access_token}`
    }})
    
    .then((response) => {
console.log("helllooooo");

     setUser(response.data.user)
     setBook(response.data.books)
     setBookTalk(response.data.bookcircle)
     setBannedUser(response.data.report)

    }
    
    )     
    .catch(err => {
      console.error(err);
      
    }); 
  } catch (error) {
    if(error.message === 'Network Error'){
        alert('no internet connection');
    }
}},[])
const handleStartDate=(val)=>{
  setStartDate(val)
}
const setTotalUser=(val)=>{
  setTotal(val)
}
const handleSearch = () => {
  try{
    axios
    .post(`${baseUrl}/admin/createInvite`,{
           "inviteePhoneNumber": searchVal,
           "invitationStatus": "PENDING",
           "inviter":"ADMIN"    },
    
    {  headers: {
      'Authorization': `Bearer ${access_token}`
    }})
    
          .then((response) => {
           alert("Invitation Added to Invited List")

    
            }
          )     
          .catch(err => {
            console.error(err);
            
          });
         
          
  
    } catch (error) {
      if(error.message === 'Network Error'){
          alert('no internet connection');
      }
      
  }
  
        
        
      }
function handleChange(event) {
  setSearchVal(event.target.value)
}

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div>

            {/* Welcome banner */}
            <WelcomeBanner />
            <div style={{}} >
              <DetailsForReviews id={id}/>
  
            </div>
          </div>
        </main>

        {/* <Banner /> */}

      </div>
    </div>
  );
}

export default Details;