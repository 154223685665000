import axios from "axios";
import { access_token, baseUrl } from "./api";

export const getBookChunks = () => {
  return axios.get(`${baseUrl}/admin/book-chunks/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });
};

export const updateBookChunks = (chunkTitle,chunkSubTitle,chunkValue) => {
  return axios.patch(`${baseUrl}/admin/updateBookChunk/`,
  {chunkTitle,chunkSubTitle,chunkValue},
  {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
  });
};

export const addBookToChunk = (chunkValue, bookId) => {
  return axios.patch(
    `${baseUrl}/admin/addBookToChunk/`,
    { book: bookId, chunkValue },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
};
export const removeBookFromChunk = (chunkValue, bookId) => {
  return axios.patch(
    `${baseUrl}/admin/removeBookFromChunk/`,
    { book: bookId, chunkValue },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
};
