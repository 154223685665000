import React from 'react';

function DashboardCard02({ type, value, label, Icon, bgColor }) {
  return (
    <div 
    style={{ padding: '20px', borderRadius: '10px', minHeight: '150px' }}
      className={`relative flex flex-col justify-between items-center col-span-full sm:col-span-6 xl:col-span-3 bg-white shadow-lg rounded-xlg border border-slate-200 ${bgColor}`}
     
    >
  
      <h4 className="absolute top-4 left-4 text-md font-semibold text-white">{type}</h4>
      
     
      <div className="flex flex-row items-center mt-12">  
        <Icon style={{ width: "32px", height: "32px", color: '#fff', marginRight: '10px' }} />
        <div className="text-lg font-semibold text-slate-100 uppercase">{label}</div>
      </div>
      
   
      <div className="text-3xl font-bold text-white mb-2">{value}</div>
    </div>
  );
}

export default DashboardCard02;
