import React, { useState,useEffect } from 'react';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import WelcomeBanner from '../partials/dashboard/WelcomeBanner';
import DashboardAvatars from '../partials/dashboard/DashboardAvatars';
import FilterButton from '../partials/actions/FilterButton';
import Datepicker from '../partials/actions/Datepicker';
import DashboardCard01 from '../partials/dashboard/DashboardCard01';
import DashboardCard02 from '../partials/dashboard/DashboardCard02';
import DashboardCard03 from '../partials/dashboard/DashboardCard03';
import DashboardCard04 from '../partials/dashboard/DashboardCard04';
import DashboardCard05 from '../partials/dashboard/DashboardCard05';
import DashboardCard06 from '../partials/dashboard/DashboardCard06';
import DashboardCard07 from '../partials/dashboard/DashboardCard07';
import DashboardCard08 from '../partials/dashboard/DashboardCard08';
import DashboardCard09 from '../partials/dashboard/DashboardCard09';
import DashboardCard10 from '../partials/dashboard/DashboardCard10';
import DashboardCard20 from '../partials/dashboard/DashboardCard20';
import DashboardCard30 from '../partials/dashboard/DashboardCard30';

import DashboardCard11 from '../partials/dashboard/DashboardCard11';
import DashboardCard12 from '../partials/dashboard/DashboardCard12';
import DashboardCard13 from '../partials/dashboard/DashboardCard13';
import Banner from '../partials/Banner';
import DashboardCard14 from '../partials/dashboard/DashboardCard14';
import DashboardCard15 from '../partials/dashboard/DashboardCard15';
import DashboardCard16 from '../partials/dashboard/DashboardCard16';
import axios from "axios";
import {baseUrl,access_token} from '../services/api';
import DashboardCard17 from '../partials/dashboard/DashboardCard17';
import DashboardCard18 from '../partials/dashboard/DashboardCard18';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import GroupIcon from '@mui/icons-material/Group';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import DashboardCard31 from '../partials/dashboard/DashboardCard31';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import BookTalk01 from '../partials/BookTalks/BookTalk01';
import BookTalk02 from '../partials/BookTalks/BookTalk02';
import BookTalk03 from '../partials/BookTalks/BookTalk03';
import BookTalk05 from '../partials/BookTalks/BookTalk05';


function Dashboard() {
  const[refresh,setRefresh]=useState(true)

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [totalUser, setTotal] = useState(0);
  const[user,setUser]=useState(0)
  const[book,setBook]=useState(0)
  const[bookTalk,setBookTalk]=useState(0)
  const[bannedUser,setBannedUser]=useState(0)
  const[searchVal,setSearchVal]=useState();
  const[phone,setPhone]=useState("");


useEffect(()=>{

  try{

        
    axios
    .get(`${baseUrl}/analytics/getAggregatedValuesAnalytics`,{  headers: {
      'Authorization': `Bearer ${access_token}`
    }})
    

    
    .then((response) => {

     setUser(response.data.user)
     setBook(response.data.books)
     setBookTalk(response.data.bookcircle)
     setBannedUser(response.data.report)

    }
    
    )     
    .catch(err => {
      console.error(err);
      
    }); 
  } catch (error) {
    if(error.message === 'Network Error'){
        alert('no internet connection');
    }
}},[])
const handleStartDate=(val)=>{
  setStartDate(val)
}
const setTotalUser=(val)=>{
  setTotal(val)
}
const handleSearch = () => {
  try{
    axios
    .post(`${baseUrl}/admin/createInvite`,{
           "inviteePhoneNumber": "+"+phone,
           "invitationStatus": "PENDING",
           "inviter":"ADMIN"
    },
    
    {  headers: {
      'Authorization': `Bearer ${access_token}`
    }})
    
          .then((response) => {
           alert(response.data.message) 
           setPhone("")   
            }
          )     
          .catch(err => {
            console.error(err);
            
          });
         
          
  
    } catch (error) {
      if(error.message === 'Network Error'){
          alert('no internet connection');
      }
      
  }
  
        
        
      }
function handleChange(event) {
  setSearchVal(event.target.value)
}

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
          <div style={{ float: "right", display: "block", padding: "8px" }}>
      
    

      {/* Input Field and Icon */}
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        {/* Phone Input */}
        <div style={{ flex: 1 }}>
        <PhoneInput
            country={'se'}
            value={phone}
            placeholder='Invite phone number to the app'
            onChange={phone => setPhone(phone)}
            inputProps={{
              name: 'phone',
              required: true,
              autoFocus: true,
            }}
            searchPlaceholder='Search countries'
            enableSearch={true}
            enableAreaCodes={true}
            countryCodeEditable={false}
            inputStyle={{
              height: '50px',
              width: '300px',
              paddingLeft: '48px',
              borderRadius: '8px',
              border: '1px solid #ccc',
              fontSize: '14px',
            }}
          />
        </div>

        
        <div
          onClick={event => handleSearch(event, searchVal)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50px",
            height: "50px",
            backgroundColor: "#134a58", // Button background color
            borderRadius: "50%", // Circular button
            cursor: "pointer",
            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
          }}
        >
          <PersonAddAlt1Icon style={{ color: "#f7dc6f", fontSize: "24px" }} />
        </div>
      </div>
    </div>
            {/* Welcome banner */}
            <WelcomeBanner />
            <div className="grid grid-cols-12 gap-6" style={{margin:'30px'}}>
            {/* <DashboardCard09 /> */}
  <DashboardCard02 
  type="Number of Users" 
  value={user} 
  label="Users" 
  Icon={GroupIcon} 
  bgColor="bg-gradient-to-r from-blue-400 to-blue-600"
/>
<DashboardCard02 
  type="Number of Books" 
  value={book} 
  label="Books" 
  Icon={MenuBookIcon} 
  bgColor="bg-gradient-to-r from-green-400 to-green-600"
/>
<DashboardCard02 
  type="Number of BookTalks" 
  value={bookTalk} 
  label="Book Talks" 
  Icon={InterpreterModeIcon} 
  bgColor="bg-gradient-to-r from-purple-400 to-purple-600"
/>
<DashboardCard02 
  type="Number of Reports" 
  value={bannedUser} 
  label="Report" 
  Icon={PersonOffIcon} 
  bgColor="bg-gradient-to-r from-red-400 to-red-600"
/>  

<DashboardCard10  userType={"All Users"} status={"Ban user"} refresh={refresh} setRefresh={setRefresh}/>
        
               <DashboardCard06 setTotalUser={setTotalUser}/>
               <DashboardCard16  totalUser={totalUser}/>
              <BookTalk01/>
              <BookTalk02/>
              <BookTalk05/>
            </div>
            {/* Dashboard actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
           
              {/* Left: Avatars */}
              <div></div>
              {/* <DashboardAvatars /> */}
            
              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Filter button */}
                {/* <FilterButton /> */}
                {/* Datepicker built with flatpickr */}
                {/* Add view button */}
                <Datepicker setStartDate={handleStartDate} />
                 <div style={{width:"50px"}}></div>
                {/* <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                    <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="hidden xs:block ml-2">Add view</span>
                </button>                 */}
              </div>
              

            </div>

            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">

              {/* Line chart (Acme Plus) */}
              {/* <DashboardCard01 /> */}

              {/* Line chart (Acme Advanced) */}
              
              {/* Line chart (Acme Professional) */}
              {/* <DashboardCard03 /> */}
              {/* Bar chart (Direct vs Indirect) */}
              {/* <DashboardCard04 /> */}
              {/* Line chart (Real Time Value) */}
              {/* <DashboardCard05 /> */}
              {/* Doughnut chart (Top Countries) */}
              {/* <DashboardCard06 /> */}
              {/* Table (Top Channels) */}
              {/* <DashboardCard07 /> */}
              {/* Line chart (Sales Over Time) */}
              
               <DashboardCard08 startDate={startDate}  /> 
               <DashboardCard14 startDate={startDate}  />
               <DashboardCard15 startDate={startDate}  />
               {/* <DashboardCard17 startDate={startDate}  />  */}
               <DashboardCard18 startDate={startDate}/> 





              {/* Stacked bar chart (Sales VS Refunds) */}
              {/* Card (Customers) */}
              <DashboardCard20 userType={"Baned Users"} status={"Unban user"} refresh={refresh} setRefresh={setRefresh}/> 
              <DashboardCard30 userType={"Reported Users"} status={"Ban user"} refresh={refresh} setRefresh={setRefresh}/>
              <DashboardCard31 userType={"Reported Reviews"} status={"Ban review"} refresh={refresh} setRefresh={setRefresh}/>
               <BookTalk03/>
              {/* <DashboardCard10 userType={"Users with Most Followers"} status={"Contact user"}/> */}
         
              {/* Card (Reasons for Refunds) */}
              {/* <DashboardCard11 /> */}
              {/* Card (Recent Activity) */}
              {/* <DashboardCard12 /> */}
              {/* Card (Income/Expenses) */}
              {/* <DashboardCard13 /> */}
              
            </div>

          </div>
        </main>

        {/* <Banner /> */}

      </div>
    </div>
  );
}

export default Dashboard;