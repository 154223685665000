import React, { useState, useEffect } from 'react';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import Form from 'react-bootstrap/Form';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Button } from 'react-bootstrap';
import { baseUrl , access_token} from '../services/api';
import { Edit, PersonPinCircle, Recycling, Remove } from '@mui/icons-material';
import axios from 'axios';
import { IconLeft } from 'react-day-picker';
import { Trash } from 'react-bootstrap-icons';
function AddReferralCode({ referralCodes = []}) {
  const [refresh, setRefresh] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch referral codes on component mount
    const fetchReferralCodes = async () => {
      try {
        const response = await fetch(`${baseUrl}/admin/getRefferalCode`, {
          method: 'GET',
          headers: {
            // Add any necessary headers, such as authentication headers
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
          },
          
          // You can add other options like credentials, mode, etc. if needed
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Datatatatattata Referral" , data);
        setData(data);
      } catch (error) {
        console.error('Error fetching referral codes:', error.message);
      }
    };

    fetchReferralCodes();
  }, [refresh]);
  const handleStartDate = (val) => {
    setStartDate(val);
  };

const columns = [
    {
      dataField: 'referalCode',
      text: 'Referral Code',
      sort: true,
      filter: textFilter({
        placeholder: 'Search by Referral Code', // Set a custom placeholder text
        className: 'from-control',  // Set a custom CSS class for styling
        delay: 1000,                        // Set a delay (in milliseconds) before applying the filter
        caseSensitive: false,
        style: {
           
            borderRadius: 5
            
        }
      }),
      
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
    },
    {
      dataField: 'expireDate',
      text: 'Expire Date',
      formatter: (cellContent, row) => {
        const date = new Date(cellContent); // Assuming cellContent is a valid date string
  
        const formattedDate = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        });
  
        return formattedDate;
      },
      sort: true,
    },
    {
      dataField: 'numberOfUserJoind',
      text: 'Number of Users Joined',
      sort: true,
    },
    
      {
        dataField: 'toggle',
        text: 'Action',
    
        formatter: (cellContent, row) => (
           
<div style={{width: 'auto', marginLeft: 9, display: 'flex'}} className="custom-control custom-switch">
  <input
    style={{flex: 1}}
    type="checkbox"
    className="custom-control-input"
    id={`toggleSwitch${row._id}`}
    checked={row.referalCodeOn} // Use the boolean value directly
    onChange={() => handleToggle(row._id, row.referalCodeOn)}
  />
  <label className="custom-control-label" htmlFor={`toggleSwitch${row._id}`}>
    {row.referalCodeOn ? 'On' : 'Off'}
  </label>
<button style={{flex:1}} className="btn btn-danger" onClick={() => handleDelete(row.referalCode)}>
 <Trash size={13} style={{alignContent: 'center'}} /> 
                </button></div>
          ),
      },
      
  ];

  const [formData, setFormData] = useState({
    referalCode: '',
    description: '',
    expireDate: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSaveButtonClick = async () => {
    try {
      // Make a POST request to add data to the database
      const response = await axios.post(
        `${baseUrl}/admin/refferalCode`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`,
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        // Data added successfully
        alert('Referral code added successfully!');
    
        setData([...data, response.data]);  
          // Reset the form or perform any additional actions
        setFormData({
          referalCode: '',
          description: '',
          expireDate: '',
        });
      } else {
        // Handle other response status codes or errors
        alert('Oops! Something went wrong. Please try again. ');
      }
    } catch (error) {
      console.error('Error adding referral code:', error.message);
      // Handle error accordingly
      alert('Oops! Something went wrong. Please try again.'+ error.message);
    }
  };

  const handleDelete = async (referalCode) => {
    const confirmed = window.confirm('Are you sure you want to delete this referral code?');
     console.log(access_token)
    if (!confirmed) {
      return;
    }
  
    try {
        const response = await axios.post(
        `${baseUrl}/admin/deleteRefferalCode`,
        {referalCode: referalCode}, 
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`,
          },
        }
      );
  
      if (response.status === 200) {
        // Data deleted successfully
        alert('Referral code deleted successfully!');
        // Update local state by removing the deleted item
        setData(data.filter(item => item.referalCode !== referalCode));
      } else {
        // Handle other response status codes or errors
        alert(`Oops! Something went wrong. Please try again. Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error deleting referral code:', error);
      alert('Oops! Something went wrong. Please try again.');
    }
  };
  
  const customStyles = {
    pagination: {
      backgroundColor: 'black',
      
      // Add any other pagination styling properties as needed
    },
    filter: {
      backgroundColor: 'black',
      // Add any other filter styling properties as needed
    },
  };
  const handleToggle = async (id, currentStatus) => {
    try {
      // Implement logic for toggling based on the id and currentStatus
      console.log(`Toggle action for referral code with id: ${id}`);
      
      // Call API to update the status accordingly
      const response = await axios.put(
        `${baseUrl}/admin/changeReferalCodeToActiveOrOFFLine/${id}`,
        {
          referalCodeOn: !currentStatus
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
          }
        }
      );
  
      if (response.status === 200) {
        alert("Referral code status updated!")
        setData((prevData) =>
        prevData.map((item) =>
          item._id === id ? { ...item, referalCodeOn: !currentStatus } : item
        )
      );
        console.log('Toggle successful');
        setRefresh(true); // Trigger a refresh after the action
      } else {
        alert("oops! please try again")
        console.error('Toggle failed:', response.statusText);
        // Handle error accordingly
      }
    } catch (error) {
      console.error('Toggle failed:', error.message);
      // Handle error accordingly
    }
  };
  
  
  return (
    
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="row" style={{border: '1.0px solid grey'}}>
              <div className="col-md-3">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label><strong>Referral Code</strong></Form.Label>
                  <Form.Control style={{ borderRadius: 10 }} type="text" placeholder="Referral Code" name="referalCode" value={formData.referalCode}
              onChange={handleInputChange} required />
                </Form.Group>
              </div>
              <div className="col-md-3">
                <Form.Group className="mb-6" controlId="exampleForm.ControlInput1">
                  <Form.Label><strong>Desription</strong></Form.Label>
                  <textarea style={{ borderRadius: 10 }} type="text" name='description' value={formData.description}
              onChange={handleInputChange} placeholder="Description"></textarea>
                
                </Form.Group>
              </div>
              <div className="col-md-3" style={{marginLeft: 5}}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label ><strong>Expire Date</strong></Form.Label>
                  <Form.Control
                    style={{ borderRadius: 10 }}
                    type="datetime-local"
                    min={
                      new Date().toISOString().split('T')[0] +
                      'T' +
                      new Date().toLocaleTimeString('en-US', { hour12: false })
                    }
                    name="expireDate"
                    value={formData.expireDate}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </div>
           </div>
            <button className="btn btn-success"  onClick={handleSaveButtonClick} style={{ margin: 5, float: 'right',width: 200, padding: 5 }}>
              Save
            </button> 
            <div>
              <BootstrapTable
                keyField="number"
                data={data}
                columns={columns}
                pagination={paginationFactory({
                  paginationOptions: {
                    custom: true,
                    paginationClass: 'custom-pagination-class',
                  },
                  ...customStyles,
                })}
                filter={filterFactory({
                  ...customStyles,
                })}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default AddReferralCode;
