import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import PieChart from '../../charts/PieChart';
import axios from "axios";
import { baseUrl, access_token } from '../../services/api';
// Import utilities
import { tailwindConfig } from '../../utils/Utils';

function DashboardCard06({ setTotalUser }) {

  const [userFreemium, setUserFreemium] = useState(0);
  const [userPremium, setUserPremium] = useState(0);
  const [userPlatinum, setUserPlatinum] = useState(0);

  const chartData = {
    labels: ['Freemium', 'Premium', 'Platinum'],
    datasets: [
      {
        label: 'Number of Users',
        data: [userFreemium, userPremium, userPlatinum],
        backgroundColor: [
          'rgba(99, 102, 241, 0.7)',  // Purple for Freemium
          'rgba(59, 130, 246, 0.7)',  // Blue for Premium
          'rgba(234, 179, 8, 0.7)',   // Yellow for Platinum
        ],
        hoverBackgroundColor: [
          'rgba(99, 102, 241, 0.9)',  // Darker Purple on Hover
          'rgba(59, 130, 246, 0.9)',  // Darker Blue on Hover
          'rgba(234, 179, 8, 0.9)',   // Darker Yellow on Hover
        ],
        borderColor: tailwindConfig().theme.colors.white,
        borderWidth: 2,
      },
    ],
  };

  useEffect(() => {
    try {
      axios
        .get(`${baseUrl}/analytics/getUsersNumbyType`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          const userType = response.data;
          userType.forEach((user) => {
            if (user._id === "FREEMIUM") {
              setUserFreemium(user.quantity);
            }
            if (user._id === "PREMIUM") {
              setUserPremium(user.quantity);
            }
            if (user._id === "PLATINUM") {
              setUserPlatinum(user.quantity);
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      if (error.message === 'Network Error') {
        alert('No internet connection');
      }
    }

    setTotalUser(userFreemium + userPremium + userPlatinum);
  }, [userFreemium, userPremium, userPlatinum]);

  return (
    <div style={{borderRadius: '15px'}} className="flex flex-col col-span-full sm:col-span-6 xl:col-span-6 bg-white shadow-lg rounded-xlg border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800">User Type Status</h2>
      </header>
      <div className="p-5">
        <Pie data={chartData} width={200} height={200} />
      </div>
    </div>
  );
}

export default DashboardCard06;
