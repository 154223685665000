import React, { useState, useEffect } from "react";
import {  Form } from 'react-bootstrap';
import "../../css/login.css";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useNavigate} from "react-router-dom"

import axios from "axios";
import {baseUrl,access_token} from '../../services/api';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { First } from "react-bootstrap/esm/PageItem";

export default function AddAdminForm() {
  const navigate = useNavigate()
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [adminRole, setAdminRole] = useState("OWNER");
 const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
 
  
  function validateForm() {
  }

  // if(email != "admin@123.com" && password != 'admin'){
  //     useEffect(
  //     setError("password and user name incorrect ")
  //     );
  //   }
  useEffect(()=>{

  },[])
  
  function handleEdit() {
        try{

        axios
        .post(`${baseUrl}/admin`,{
              "firstName":firstName,
               "lastName": lastName,
               "email": email,
               "role": adminRole,
        },
        
        {  headers: {
          'Authorization': `Bearer ${access_token}`
        }})
        
        .then((response) => {
            alert(response.data.message)
           }

        )     
        .catch(err => {
          console.error(err);
          
        });
       
        

  } catch (error) {
    if(error.message === 'Network Error'){
        alert('no internet connection');
    }
    
}
setOpen(false);

     
  }

  return (
  <div className="maincontainer">
    <div class="container-fluid">
        <div class="row no-gutter">
           
            <div class="col-md-12 ">
              <div class="col-md-9  ">

             
                <div class="login d-flex align-items-center py-5">
                   
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-10 col-xl-7 mx-auto">
                                <center><h1 class="display-4" style={{color:"black"}}>Add Admin</h1></center>
                                <form>
                                    <div class="form-group mb-3">
                                        <label  for="firstName">First Name:</label>
                                        <input id="firstName" 
                                         type="text" placeholder={"Enter first Name"} required  autoFocus
                                         value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="lastName">Last Name:</label>
                                        <input id="lastName"
                                         type="text" placeholder={"Enter Last Name"} required  autoFocus
                                         value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="email">Email:</label>
                                        <input id="email"
                                         type="email" placeholder={"Enter Email Address"} required  autoFocus
                                         value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="adminRole" style={{padding:"10px"}}>Admin Role:</label>
                                        {/* <input id="adminRole"
                                         type="text" placeholder={100} required=""  autoFocus
                                         value={adminRole}
                                        onChange={(e) => setAdminRole(e.target.value)}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                         */}

                                        <select id="cars" name="cars"
                                              value={adminRole} // ...force the select's value to match the state variable...
                                              defaultValue="OWNER"
                                            onChange={(e) => setAdminRole(e.target.value)} >
  <option  value="OWNER">OWNER</option>
  <option value="ADMIN">ADMIN</option>
</select>
                                    </div>
                         
                                    {/* <div class="custom-control custom-checkbox mb-3">
                                        <input id="customCheck1" type="checkbox" checked class="custom-control-input" />
                                        <label for="customCheck1" class="custom-control-label">Remember password</label>
                                    </div> */}
                                    <div>
      <div  class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill" style={{backgroundColor:"blue",margin:"20px"}} onClick={handleClickOpen} >Submit</div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you Sure ? Do you want to Add this user as Admin?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Cancle</Button>
          <Button onClick={handleEdit} >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
                                    {/* <div class="text-center d-flex justify-content-between mt-4"><p>Code by <a href="#" class="font-italic text-muted"> 
                                            <u>Jassa</u></a></p></div> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div> 
                
            </div>
            </div>
        </div>
    </div>
  </div>

  
  );
 
}