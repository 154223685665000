import axios from "axios";
import { access_token, baseUrl } from "./api";

export const getPopularBooks = () => {
    return axios.get(`${baseUrl}/admin/popularBooks/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  };

  export const getReviews = (userType) => {
    return axios.get(`${baseUrl}/admin/getReviews/${userType}/1`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  };

  export const getAuthors = () => {
    return axios.get(`${baseUrl}/admin/getAuthors/1`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  };

  export const getFamousPersons = () => {
    return axios.get(`${baseUrl}/admin/getFamousPersons/1`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  };

  export const getPopularGenres = () => {
    return axios.get(`${baseUrl}/admin/popularGenres/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  };
  export const getMoniroFeaturedBooks = () => {
    return axios.get(`${baseUrl}/admin/moniroFeaturedBooks/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  };
  export const getMoniroRecommendedBooks = () => {
    return axios.get(`${baseUrl}/admin/moniroRecommendedBooks/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  };
  export const getMoniroPopularBooks = () => {
    return axios.get(`${baseUrl}/admin/moniroPopularBooks/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  };
  export const getMoniroFeaturedReviews = () => {
    return axios.get(`${baseUrl}/admin/moniroFeaturedReviews/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  };
  export const getMoniroFeaturedAuthors = () => {
    return axios.get(`${baseUrl}/admin/moniroFeaturedAuthors/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  };

  export const getMoniroFeaturedFamousPersons = () => {
    return axios.get(`${baseUrl}/admin/moniroFeaturedFamousPersons/1`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  };

  export const inViteGlobal = (id) => {
    return axios.get(`${baseUrl}/admin/invite-global/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  };

  export const updateBookState=(id,state,value) => {
    return axios.patch(`${baseUrl}/admin/updateBookState/${id}`, {
      [state]: value,
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  export const updateReviewState=(id,state,value) => {
    return axios.patch(`${baseUrl}/admin/updateReviewState/${id}`, {
      [state]: value,
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  }
  export const updateAuthorState=(id,state,value,obj) => {
    return axios.patch(`${baseUrl}/admin/updateAuthorState/${id}`, obj||{
      [state]: value,
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  export const addBookAuthor=(id,book) => {
    return axios.patch(`${baseUrl}/admin/addBookAuthor/${id}`, {
      book,
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  }
  export const removeBookAuthor=(id,book) => {
    return axios.patch(`${baseUrl}/admin/removeBookAuthor/${id}`, {
      book,
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  }

  export const updateFamousPersonState=(id,state,value, obj) => {
    return axios.patch(`${baseUrl}/admin/updateFamousPersonState/${id}`, obj||{
      [state]: value,
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  }
  export const addBookFamousPerson=(id,book) => {
    return axios.patch(`${baseUrl}/admin/addBookFamousPerson/${id}`, {
      book,
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  }
  export const removeBookFamousPerson=(id,book) => {
    return axios.patch(`${baseUrl}/admin/removeBookFamousPerson/${id}`, {
      book,
    },{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
  }