import React, { useState } from 'react';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import WelcomeBanner from '../partials/dashboard/WelcomeBanner';
import DashboardAvatars from '../partials/dashboard/DashboardAvatars';
import FilterButton from '../partials/actions/FilterButton';
import Datepicker from '../partials/actions/Datepicker';
import DashboardCard01 from '../partials/dashboard/DashboardCard01';
import DashboardCard02 from '../partials/dashboard/DashboardCard02';
import DashboardCard03 from '../partials/dashboard/DashboardCard03';
import DashboardCard04 from '../partials/dashboard/DashboardCard04';
import DashboardCard05 from '../partials/dashboard/DashboardCard05';
import DashboardCard06 from '../partials/dashboard/DashboardCard06';
import DashboardCard07 from '../partials/dashboard/DashboardCard07';
import DashboardCard08 from '../partials/dashboard/DashboardCard08';
import DashboardCard09 from '../partials/dashboard/DashboardCard09';
import DashboardCard10 from '../partials/dashboard/DashboardCard10';
import DashboardCard20 from '../partials/dashboard/DashboardCard20';
import DashboardCard30 from '../partials/dashboard/DashboardCard30';

import DashboardCard11 from '../partials/dashboard/DashboardCard11';
import DashboardCard12 from '../partials/dashboard/DashboardCard12';
import DashboardCard13 from '../partials/dashboard/DashboardCard13';
import Banner from '../partials/Banner';
import DashboardCard14 from '../partials/dashboard/DashboardCard14';
import DashboardCard15 from '../partials/dashboard/DashboardCard15';
import WaitedListCard from '../partials/dashboard/WaitedListCard';
import SearchIcon from '@mui/icons-material/Search';
// import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import axios from "axios";
import {baseUrl,access_token} from '../services/api';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
function Users() {
  const[refresh,setRefresh]=useState(true)
  const [totalUser, setTotal] = useState(0);
  const[searchVal,setSearchVal]=useState();
  const[phone,setPhone]=useState("");

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
// useEffect(()=>{

// },[])
const handleStartDate=(val)=>{
  setStartDate(val)
}
const setTotalUser=(val)=>{
  setTotal(val)
}
const handleSearch = () => {
  try{
    axios
    .post(`${baseUrl}/admin/createInvite`,{
           "inviteePhoneNumber": "+"+phone,
           "invitationStatus": "PENDING",
           "inviter":"ADMIN"    },
    
    {  headers: {
      'Authorization': `Bearer ${access_token}`
    }})
    
          .then((response) => {
           alert(response.data.message)
            setPhone("")
    
              ("harrassmmeent",response);}
          )     
          .catch(err => {
            console.error(err);
            
          });
         
          
  
    } catch (error) {
      if(error.message === 'Network Error'){
          alert('no internet connection');
      }
      
  }
  
        
        
      }
function handleChange(event) {
  setSearchVal(event.target.value)
}
  return (
    <div className="flex h-screen overflow-hidden">

    
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

   
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            <WelcomeBanner />

           
              
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"> 
              <Datepicker setStartDate={handleStartDate} />
              
              <div style={{float: "right",display:"block",padding:"8px"}}>

              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        {/* Phone Input */}
        <div style={{ flex: 1 }}>
        <PhoneInput
            country={'se'}
            value={phone}
            placeholder='Invite phone number to the app'
            onChange={phone => setPhone(phone)}
            inputProps={{
              name: 'phone',
              required: true,
              autoFocus: true,
            }}
            searchPlaceholder='Search countries'
            enableSearch={true}
            enableAreaCodes={true}
            countryCodeEditable={false}
            inputStyle={{
              height: '50px',
              width: '300px',
              paddingLeft: '48px',
              borderRadius: '8px',
              border: '1px solid #ccc',
              fontSize: '14px',
            }}
          />
        </div>

        
        <div
          onClick={event => handleSearch(event, searchVal)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50px",
            height: "50px",
            backgroundColor: "#134a58", // Button background color
            borderRadius: "50%", // Circular button
            cursor: "pointer",
            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
          }}
        >
          <PersonAddAlt1Icon style={{ color: "#f7dc6f", fontSize: "24px" }} />
        </div>
      </div>         
               </div>
               
              </div>
        <div className='grid grid-cols-12 gap-6'>
               <DashboardCard08 startDate={startDate}  /> 

                <DashboardCard06 setTotalUser={setTotalUser}/>
               <DashboardCard10 userType={"All Users"} status={"Ban user"} refresh={refresh} setRefresh={setRefresh}/>
              <DashboardCard20 userType={"Baned Users"} status={"Unban user"} refresh={refresh} setRefresh={setRefresh}/> 
              <DashboardCard30 userType={"Reported Users"} status={"Ban user"} refresh={refresh} setRefresh={setRefresh}/>
              <WaitedListCard/>
</div>
          </div>
        </main>

        {/* <Banner /> */}

      </div>
    </div>
  );
}

export default Users;