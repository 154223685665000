import React, { useState } from 'react';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import WelcomeBanner from '../partials/dashboard/WelcomeBanner';
import DashboardAvatars from '../partials/dashboard/DashboardAvatars';
import FilterButton from '../partials/actions/FilterButton';
import Datepicker from '../partials/actions/Datepicker';
import DashboardCard06 from '../partials/dashboard/DashboardCard06';
import DashboardCard08 from '../partials/dashboard/DashboardCard08';
import DashboardCard10 from '../partials/dashboard/DashboardCard10';
import DashboardCard20 from '../partials/dashboard/DashboardCard20';
import DashboardCard30 from '../partials/dashboard/DashboardCard30';
import BookTalk01 from '../partials/BookTalks/BookTalk01';
import BookTalk02 from '../partials/BookTalks/BookTalk02';
import BookTalk03 from '../partials/BookTalks/BookTalk03';
import BookTalk04 from '../partials/BookTalks/BookTalk04';
import BookTalk05 from '../partials/BookTalks/BookTalk05';
import FormSystemSettings from '../partials/actions/FormSystemSettings';

function BookTalks() {
  const[refresh,setRefresh]=useState(true)

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
// useEffect(()=>{

// },[])
const handleStartDate=(val)=>{
  setStartDate(val)
}


  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            <WelcomeBanner />

 
              <div></div>
      
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"> 
            
              </div>
                <FormSystemSettings/>
          </div>
        </main>

        {/* <Banner /> */}

      </div>
    </div>
  );
}

export default BookTalks;