import { Card, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Image from 'react-bootstrap/Image';
import { Star, StarFill } from 'react-bootstrap-icons';
function ReviewCard({ review, forUser }) {
    const fullStars = Math.floor(review.rating);
    const halfAStar = review.rating - fullStars;
    const defaultImageUrl = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK8ffDsJ4jPCqnGI6CmuPG9MHW22ophfZauvpt6qO5qnJz6oKjsySCrmqgrEZLk30ufKs&usqp=CAU";

    const starsArray = Array(fullStars).fill(1);
  
    return (
      // <div className="w-fit h-fit flex flex-col text-small-1 sm:text-body-2 items-center gap-4 sm:gap-6 p-2 sm:p-4 font-body bg-gradient1/60 backdrop-blur-sm rounded-lg text-white sm:rounded-xl bg-slate-600 my-3">
      //   <p className="max-w-56 sm:max-w-96">{review.message}</p>
      //   <div className="flex flex-row justify-between pr-6 w-full">
      //     <div className="flex flex-row items-center w-fit gap-4">
      //       <div
      //         className={
      //           (forUser
      //             ? `rounded-lg drop-shadow-book h-14 sm:h-20 `
      //             : `rounded-full h-10 sm:h-14 `) +
      //           ` w-10 sm:w-14 bg-cover bg-center`
      //         }
      //         style={{ backgroundImage: `url(${review.imageUrl})` }}
      //       ></div>
      //       <div className="flex flex-col gap-1">
      //         {forUser && (
      //           <p className="text-small-1 sm:text-body-2 text-gray-400 -mb-1">
      //             On
      //           </p>
      //         )}
      //         <p className="text-body-2 sm:text-body-1">{review.userType} - {review.userName}</p>
      //         <div className="w-28 sm:w-36 items-start flex gap-0.5">
      //           {starsArray.map((v, i) => (
      //             <span
      //               key={i}
      //               className="icon-[fluent--star-24-filled] bg-yellow-400 drop-shadow-card w-3 h-3 sm:w-4 sm:h-4"
      //             ></span>
      //           ))}
      //           {halfAStar > 0 && (
      //             <span className="icon-[fluent--star-half-24-filled] bg-yellow-400 drop-shadow-card w-3 h-3 sm:w-4 sm:h-4"></span>
      //           )}
      //         </div>
      //       </div>
      //     </div>
      //     <span className="icon-[material-symbols--format-quote]  drop-shadow-card w-6 h-6 sm:w-8 sm:h-8"></span>
      //   </div>
      // </div>




<Card border="pr" style={{ width: '850px', padding: '10px', margin: '10px' }}>
      <Card.Header>
        <Row className="align-items-center">
          <Col xs="auto">
            <Image  src={review.imageUrl ? review.imageUrl : defaultImageUrl} roundedCircle style={{width: '40px', height: '40px', imageResolution: 'from-image'}}/>
          </Col>
          <Col>
            <span>{review.userName}</span>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Card.Title>{review.userType+" User"}</Card.Title>
        <Card.Text>
          {review.message} <br/>
          <div className="w-28 sm:w-36 items-start flex gap-0.5">
                {starsArray.map((v, i) => (
                  //  <span
                  //    key={i}
                  //    className="icon-[fluent--star-24-filled] bg-yellow-400 drop-shadow-card w-3 h-3 sm:w-4 sm:h-4"
                  // ></span>
                  <StarFill key={i} color='#F5B041' />
               ))}
             {halfAStar > 0 && (
                 <span className="icon-[fluent--star-half-24-filled] bg-yellow-400 drop-shadow-card w-3 h-3 sm:w-4 sm:h-4"></span>
               )}
             </div>
        </Card.Text>
      </Card.Body>
    </Card>


    );
  }
  export default ReviewCard;
  