import React from 'react';

function WelcomeBanner() {
  const today = new Date();
  const curHr = today.getHours();
  let Greetings = '';

  if (curHr < 12) {
    Greetings = 'Good Morning';
  } else if (curHr < 18) {
    Greetings = 'Good Afternoon';
  } else {
    Greetings = 'Good Evening';
  }

  return (
    <div
      className="relative bg-gradient-to-r from-yellow-400 via-yellow-500 to-[#134a58] p-6 sm:p-8 rounded-lg shadow-lg overflow-hidden mb-8 animate-slowPulse"
      style={{ width: '100%' }}
    >
      {/* Background illustration */}
      <div className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block" aria-hidden="true">
        <svg width="319" height="198" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
            <path id="welcome-a" d="M64 0l64 128-64-20-64 20z" />
            <path id="welcome-e" d="M40 0l40 80-40-12.5L0 80z" />
            <path id="welcome-g" d="M40 0l40 80-40-12.5L0 80z" />
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="welcome-b">
              <stop stopColor="#F8D774" offset="0%" />
              <stop stopColor="#F1C40F" offset="100%" />
            </linearGradient>
            <linearGradient x1="50%" y1="24.537%" x2="50%" y2="100%" id="welcome-c">
              <stop stopColor="#C49A0A" offset="0%" />
              <stop stopColor="#E8A922" stopOpacity="0" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="rotate(64 36.592 105.604)">
              <mask id="welcome-d" fill="#fff">
                <use xlinkHref="#welcome-a" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-a" />
              <path fill="url(#welcome-c)" mask="url(#welcome-d)" d="M64-24h80v152H64z" />
            </g>
            <g transform="rotate(-51 91.324 -105.372)">
              <mask id="welcome-f" fill="#fff">
                <use xlinkHref="#welcome-e" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-e" />
              <path fill="url(#welcome-c)" mask="url(#welcome-f)" d="M40.333-15.147h50v95h-50z" />
            </g>
            <g transform="rotate(44 61.546 392.623)">
              <mask id="welcome-h" fill="#fff">
                <use xlinkHref="#welcome-g" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-g" />
              <path fill="url(#welcome-c)" mask="url(#welcome-h)" d="M40.333-15.147h50v95h-50z" />
            </g>
          </g>
        </svg>
      </div>

      {/* Content */}
      <div className="relative">
        <h1 className="text-4xl md:text-5xl text-white font-bold mb-2 animate-scaleUp">
          {Greetings}, {localStorage.getItem('firstName')} 👋
        </h1>
        <p className="text-lg text-white opacity-90 animate-fadeIn delay-1s">
          Here's what’s happening with your App 
        </p>
      </div>

      {/* Add CSS Animations */}
      <style>
        {`
          @keyframes fadeIn {
            0% { opacity: 0; }
            100% { opacity: 1; }
          }

          @keyframes scaleUp {
            0% { transform: scale(0.95); opacity: 0; }
            100% { transform: scale(1); opacity: 1; }
          }

          @keyframes slowPulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.05); }
            100% { transform: scale(1); }
          }

          .animate-fadeIn {
            animation: fadeIn 1.5s ease forwards;
          }

          .animate-scaleUp {
            animation: scaleUp 2s ease forwards;
          }

          .animate-slowPulse {
            animation: slowPulse 10s ease-in-out infinite;
          }
        `}
      </style>
    </div>
  );
}

export default WelcomeBanner;
