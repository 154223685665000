import React,{useState,useEffect}  from 'react';
import axios from "axios";
import {baseUrl,access_token} from '../../services/api';
import SearchIcon from '@mui/icons-material/Search';
import AppString from '../components/AppStiring';
function BookTalk05() {
  const[searchVal,setSearchVal]=useState();

 const[ customers,setCustomers]=useState([])
 const[ refresh,setReferash]=useState(true)

 const[ page,setPage]=useState(1)
 const[ buttonText,setButtonText]=useState("See More")
 const[ searchedUser,setSearchedUser]=useState([])

  useEffect(()=>{

    try{

  
          axios
          .get(`${baseUrl}/analytics/getTimeSpentByTalksGroupedByTalkId/${page}`,{  headers: {
            'Authorization': `Bearer ${access_token}`
          }})
          
          .then((response) => {
      
              setCustomers(response.data.timeSpentByTalksGroupedByTalkId)
            ("respons05",response);
          }
          
          )     
          .catch(err => {
            console.error(err);
            
          });
         
          
  
    } catch (error) {
      if(error.message === 'Network Error'){
          alert('no internet connection');
      }
      
}},[page]

)
const handleLoadMore=()=>{
  if (buttonText=="See Less") {
    setReferash(false)
  }
  setPage(customers.length<10?page:page+1)

}
const handleLoadLess=()=>{
  if (buttonText=="See Less") {
    setReferash(false)
  }
  setPage(page<=1?1:page-1)

}
const handleClick = (event, param) => {


  try{

  
    axios
    .patch(`${baseUrl}/admin/adminUpdate/${param.id}`,{
      "userStatus":`${param.value=="BANNED"?"UNBANNED":"BANNED"}`
  },{  headers: {'Content-Type': 'application/json',
      'Authorization': `Bearer ${access_token}`
    },
  })
    
    .then((response) => {
 
     alert(`USER ${param.value=="BANNED"?"UNBANNED":"BANNED"} SUCCESSFULLY!`)

    }
    
    )     
    .catch(err => {
      console.error(err);
      
    });
   
    

} catch (error) {
if(error.message === 'Network Error'){
    alert('no internet connection');
}
}
};

const handleSearch = (event, param) => {

  if (!param||param.length==0) {
  }else{
  try{


    axios
    .get(`${baseUrl}/admin/getUserByEmailOrPhoneNumber/${param}`,{  headers: {
      'Authorization': `Bearer ${access_token}`
    },
  })
    
    .then((response) => {
   
      setSearchedUser(response.data)
      // setRefresh(!refresh)

    }
    
    )     
    .catch(err => {
      console.error(err);
      
    });
   
    

} catch (error) {
if(error.message === 'Network Error'){
    alert('no internet connection');
}
}}

};
function handleChange(event) {
 
  setSearchVal(event.target.value)
  handleSearch(event, event.target.value);
}
  return (
   
    <div style={{ borderRadius: '15px' }} className="col-span-full xl:col-span-12 bg-white shadow-lg border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100" >
        <h2 className="font-semibold text-slate-800" style={{width:'50%',display:'inline-block'}}>{"Talks With Most Time Spent"}</h2>

        
      </header>
      <div className="p-3">
  <div className="overflow-y-auto" style={{ maxHeight: '400px' }}> {/* Fixed height with vertical scroll */}
    <table className="table-auto w-full">
      <thead className="text-xs font-semibold uppercase text-slate-400 bg-slate-50">
        <tr>
          <th className="p-2 whitespace-nowrap">
            <div className="font-semibold text-left">Title</div>
          </th>
          <th className="p-2 whitespace-nowrap">
            <div className="font-semibold text-left">Author</div>
          </th>
          <th className="p-2 whitespace-nowrap">
            <div className="font-semibold text-left">Time Spent on Talks (min)</div>
          </th>
          <th className="p-2 whitespace-nowrap">
            <div className="font-semibold text-left">Talk Creator Name</div>
          </th>
         
        </tr>
      </thead>
      <tbody className="text-sm divide-y divide-slate-100">
        {customers.map((customer, index) => (
          <tr key={customer._id} className={`${index % 2 === 0 ? 'bg-slate-50' : 'bg-white'} hover:bg-slate-100`}>
            <td className="p-2 whitespace-nowrap">
            <div className="flex items-center">
    <div className="w-12 h-16 shrink-0 mr-2 sm:mr-3"> 
      <img
        className="rounded-bl-md rounded-br-md rounded-tr-lg" 
        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
        src={!customer.book || customer.book.imageUrl === "" 
          ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png' 
          : customer.book.imageUrl}
        alt={customer.book.title}
      />
    </div>
    <div className="font-medium text-slate-800"><AppString>{customer.book.title}</AppString></div>
  </div>
            </td>
            <td className="p-2 whitespace-nowrap">
              <div className="text-left">{customer.book.author}</div>
            </td>
            <td className="p-2 whitespace-nowrap">
              <div className="text-left font-medium text-green-500">{customer.total}</div>
            </td>
            <td className="p-2 whitespace-nowrap">
              <div className="text-left font-medium text-black-500">{customer.user.userInfo.firstName + " " + customer.user.userInfo.lastName}</div>
            </td>
            
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  <div style={{ width: "100%", textAlign: "right" }}>
    {page > 1 && (
      <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white" onClick={handleLoadLess}>
        {"Previous"}
      </button>
    )}
    {customers.length >= 10 && (
      <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white" style={{ margin: "10px" }} onClick={handleLoadMore}>
        {"Next"}
      </button>
    )}
  </div>
</div>

    </div>
  );
}

export default BookTalk05;
