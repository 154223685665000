import React, { useState, useEffect } from "react";
import {  Form } from 'react-bootstrap';
import "../../css/login.css";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useNavigate} from "react-router-dom"

import axios from "axios";
import {baseUrl,access_token} from '../../services/api';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function FormSystemSettings() {
  const navigate = useNavigate()
  const [referashTimeForTalks, setReferashTimeForTalks] = useState(7)
  const [totalTimeForParticepant, setTotalTimeForParticepant] = useState(600);
  const [totalTimeForSpeaker, setTotalTimeForSpeaker] = useState(180);
  const [limitOfOngoingBookTalkAUserCanHave, setLimitOfOngoingBookTalkAUserCanHave] = useState(100);
  const [limitOfBookAUserCanHave, setLimitOfBookAUserCanHave] = useState(200);
  const [totalNumberOfInvitation, setTotalNumberOfInvitation] = useState(20);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
 
  
  function validateForm() {
  }

  // if(email != "admin@123.com" && password != 'admin'){
  //     useEffect(
  //     setError("password and user name incorrect ")
  //     );
  //   }
  useEffect(()=>{
    try{

              axios
              .get(`${baseUrl}/system-settings`
              ,
              {  headers: {
                'Authorization': `Bearer ${access_token}`
              }})
              
              .then((response) => {
                setTotalNumberOfInvitation(response.data.totalNumberOfInvitation)
                setTotalTimeForParticepant(response.data.totalTimeForParticepant)
                setTotalTimeForSpeaker(response.data.totalTimeForSpeaker)
                setReferashTimeForTalks(response.data.referashTimeForTalks)
                setLimitOfBookAUserCanHave(response.data.limitOfBookAUserCanHave)
                setLimitOfOngoingBookTalkAUserCanHave(response.data.limitOfOngoingBookTalkAUserCanHave)


        }
              )     
              .catch(err => {
                console.error(err);
                
              });
             
              
      
        } catch (error) {
          if(error.message === 'Network Error'){
              alert('no internet connection');
          }
          
      }
  },[])
  
  function handleEdit() {
        try{

        axios
        .patch(`${baseUrl}/system-settings`,{
              "totalTimeForParticepant":totalTimeForParticepant,
               "totalTimeForSpeaker": totalTimeForSpeaker,
               "limitOfBookAUserCanHave": limitOfBookAUserCanHave,
               "limitOfOngoingBookTalkAUserCanHave": limitOfOngoingBookTalkAUserCanHave,
               "referashTimeForTalks": referashTimeForTalks,
               "totalNumberOfInvitation":totalNumberOfInvitation
        },
        
        {  headers: {
          'Authorization': `Bearer ${access_token}`
        }})
        
        .then((response) => {
            alert(response.data.message)
            }

        )     
        .catch(err => {
          console.error(err);
          
        });
       
        

  } catch (error) {
    if(error.message === 'Network Error'){
        alert('no internet connection');
    }
    
}
setOpen(false);

     
  }

  return (
  <div className="maincontainer">
    <div class="container-fluid">
        <div class="row no-gutter">
           
            <div class="col-md-12 ">
              <div class="col-md-9  ">

             
                <div class="login d-flex align-items-center py-5">
                   
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-10 col-xl-7 mx-auto">
                                <center><h1 class="display-4" style={{color:"black"}}>System Settings</h1></center>
                                <form>
                                    <div class="form-group mb-3">
                                        <label  for="timeSpeaker">Limit total Time For Speaker in min:</label>
                                        <input id="timeSpeaker" 
                                         type="number" placeholder={180} required=""  autoFocus
                                         value={totalTimeForSpeaker}
                                        onChange={(e) => setTotalTimeForSpeaker(e.target.value)}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="timeParticepant">Limit total Time For Particepant in min:</label>
                                        <input id="timeParticepant"
                                         type="number" placeholder={600} required=""  autoFocus
                                         value={totalTimeForParticepant}
                                        onChange={(e) => setTotalTimeForParticepant(e.target.value)}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="limitBook">Limit number of book a user can have:</label>
                                        <input id="limitBook"
                                         type="number" placeholder={200} required=""  autoFocus
                                         value={limitOfBookAUserCanHave}
                                        onChange={(e) => setLimitOfBookAUserCanHave(e.target.value)}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="limitBookTalk">Limit of ongoing book talk a user can have:</label>
                                        <input id="limitBookTalk"
                                         type="number" placeholder={100} required=""  autoFocus
                                         value={limitOfOngoingBookTalkAUserCanHave}
                                        onChange={(e) => setLimitOfOngoingBookTalkAUserCanHave(e.target.value)}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="resetTimeSpent">Reset Talk spent Time by users in days</label>
                                        <input id="resetTimeSpent"
                                         type="number" placeholder={7} required=""  autoFocus
                                         value={referashTimeForTalks}
                                        onChange={(e) => setReferashTimeForTalks(e.target.value)}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="numberOfInvitation">Total number of invitation</label>
                                        <input id="numberOfInvitation"
                                         type="number" placeholder={20} required=""  autoFocus
                                         value={totalNumberOfInvitation}
                                        onChange={(e) => setTotalNumberOfInvitation(e.target.value)}
                                         class="form-control rounded-pill border-0 shadow-sm px-4" />
                                    </div>
                                    {/* <div class="custom-control custom-checkbox mb-3">
                                        <input id="customCheck1" type="checkbox" checked class="custom-control-input" />
                                        <label for="customCheck1" class="custom-control-label">Remember password</label>
                                    </div> */}
                                    <div>
      <div  class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill" style={{backgroundColor:"blue"}} onClick={handleClickOpen} >Submit</div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit System Settings"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you Sure ? Do you want to edit System Settings?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Cancle</Button>
          <Button onClick={handleEdit} >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
                                    {/* <div class="text-center d-flex justify-content-between mt-4"><p>Code by <a href="#" class="font-italic text-muted"> 
                                            <u>Jassa</u></a></p></div> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div> 
                
            </div>
            </div>
        </div>
    </div>
  </div>

  
  );
 
}