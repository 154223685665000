import React, { useState } from "react";

const AppString = ({len=17, children }) => {
const text = children;

return (
	<p className="text" style={{color:'black',textAlign:'start'}}>
	{text.length>len ? text.slice(0, len) : text}
	
	</p>
);
};


export default AppString;
