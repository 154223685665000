import axios from "axios";
import React, { useEffect, useState } from "react";
// import AuthorCard from '../components/AuthorCard';
import Switch from "react-input-switch";
import { access_token, baseUrl } from "../../services/api";
import AppString from "./AppStiring";
import { Button } from "react-bootstrap";

function SearchBook(props) {
  const { primaryAction, secondaryAction, books } = props;
  const [searchedUser, setSearchedUser] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [showAddedBooks, setShowAddedBooks] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    console.log("books:", books);
  }, []);
  const handleSearch2 = async (event, param) => {
    try {
      const response = await axios.get(
        `${baseUrl}/admin/search?query=${param}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      // Check if 'data' and 'data.data' properties exist in the response
      if (!response.data || !response.data.success || !response.data.data) {
        console.log("Invalid response format or no data found.");
        setSearchedUser([]);
        return;
      }

      console.log("Custom search response:", response.data.data);

      // Transform the data into the desired format
      const cust = response.data.data.map((book) => ({
        _id: book._id,
        title: book.title || "",
        author: book.author || "Not Known",
        imageUrl: book.imageUrl || "",
        description: book.description || "",
        language: book.language || "",
        publisher: book.publisher || "",
        publishedDate: book.publishedDate || "",
        genre: book.genre || "",
        subtitle: book.subTitle || "",
        isbn: book.isbn || "",
        bookAddedAs: book.bookAddedAs || "",
      }));

      console.log("Transformed data:", cust);

      // Update state with the transformed data
      setSearchedUser(cust);
      setRefresh(!refresh);
    } catch (error) {
      // Handle errors
      setSearchedUser([]);
      console.error(error);

      if (error.message === "Network Error") {
        alert("No internet connection");
      }
    }
  };

  function handleChange2(event) {
    setSearchVal(event.target.value);
    handleSearch2(event, event.target.value);
  }

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800">Authors</h2>
      </header>
      <header className="px-5 py-4 border-b border-slate-100">
        <h2
          className="font-semibold text-slate-800"
          style={{ width: "15%", display: "inline-block" }}
        ></h2>
        <input
          placeholder="Search Book From Database"
          style={{
            float: "left",
            borderRadius: "10px",
            border: "solid",
            width: '300px'
          }}
          onChange={handleChange2}
        />
      </header>
      {books && (
        <div className="overflow-x-auto" style={{ overflow: "scroll" }}>
          
          <Button

           style={{margin: 10}}
            className="text-blue-500"
            onClick={() => {
              setShowAddedBooks(!showAddedBooks);
            }}
          >
            {showAddedBooks ? "Hide added books" : "Show added books"}
          </Button>

          {showAddedBooks && (
            <h2 className="font-semibold text-slate-800">
              Added books: {books.length == 0 ? "empty" : ""}
            </h2>
          )}
          {showAddedBooks && books.length > 0 && (
            <table className="table-auto w-full">
              {/* Table header */}

              <thead className="text-xs font-semibold uppercase text-slate-400 bg-slate-50">
                <tr>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Title</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Author</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Status</div>
                  </th>
                  {/* <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Total number of report</div>
                </th> */}
                </tr>
              </thead>

              {/* Table body */}
              <tbody className="text-sm divide-y divide-slate-100">
                {books.map((book) => {
                  return (
                    <tr key={book._id}>
                      <td className="p-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
                            <img
                              className="rounded-full"
                              style={{
                                objectFit: "cover",
                                width: "40px",
                                height: "40px",
                              }}
                              src={
                                book.imageUrl === ""
                                  ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                                  : book.imageUrl
                              }
                              alt={book.title}
                            />
                          </div>
                          <div className="font-medium text-slate-800">
                            <AppString>{book.title}</AppString>
                          </div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">
                          <AppString>{book.author}</AppString>
                        </div>
                      </td>
                      <td>
                        <div
                          style={{ flex: 1, marginInlineStart: 5 }}
                          className="text-right font-medium text-red-500"
                        >
                          <button onClick={() => secondaryAction(book)}>
                            Remove
                          </button>
                        </div>
                      </td>
                      <td
                        style={{ display: "flex", width: "50px" }}
                        className="p-2 whitespace-nowrap"
                      ></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      )}
      {searchVal.length != 0 && (
        <div
          className="overflow-x-auto"
          style={{ height: "350px", overflow: "scroll" }}
        >
          <h2 className="font-semibold text-slate-800">Search results:</h2>
          <table className="table-auto w-full">
            {/* Table header */}
            {searchedUser.length > 0 && (
              <thead className="text-xs font-semibold uppercase text-slate-400 bg-slate-50">
                <tr>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Title</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Author</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Status</div>
                  </th>
                  {/* <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Total number of report</div>
                </th> */}
                </tr>
              </thead>
            )}
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-100">
              {searchedUser.map((book) => {
                return (
                  <tr key={book._id}>
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
                          <img
                            className="rounded-full"
                            style={{
                              objectFit: "cover",
                              width: "40px",
                              height: "40px",
                            }}
                            src={
                              book.imageUrl === ""
                                ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                                : book.imageUrl
                            }
                            alt={book.title}
                          />
                        </div>
                        <div className="font-medium text-slate-800">
                          <AppString>{book.title}</AppString>
                        </div>
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                      <div className="text-left">
                        <AppString>{book.author}</AppString>
                      </div>
                    </td>
                    <td>
                      <div
                        style={{ flex: 1, marginInlineStart: 5 }}
                        className="text-right font-medium text-green-500"
                      >
                        {books.filter((bk)=>book._id==bk._id).length==0?<button onClick={() => primaryAction(book)}>
                          Add
                        </button>:'Added'}
                      </div>
                    </td>
                    <td
                      style={{ display: "flex", width: "50px" }}
                      className="p-2 whitespace-nowrap"
                    ></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default SearchBook;
