import React, { useRef, useEffect } from 'react';
import { Chart, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

Chart.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

function BarChart({ data, width, height }) {
  const canvas = useRef(null);

  useEffect(() => {
    const ctx = canvas.current;
    const chart = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
              },
            },
          },
        },
        scales: {
            x: {
                type: 'time',
                time: {
                  parser: 'YYY-MM-DD',
                  unit: 'day',
                  displayFormats: {
                    month: 'MMM DD',
                  },
                },
                border: {
                  display: true,
                },
                grid: {
                  display: false,
                },
                ticks: {
                  // autoSkipPadding: 48,
                  // maxRotation: 0,
                },
              },
          y: {
            
            // beginAtZero: true,
            stacked: true
          },
        },
      },
    });
    return () => chart.destroy();
  }, [data]);

  return (
    <div className="flex flex-col">
      <canvas ref={canvas} width={width} height={height}></canvas>
    </div>
  );
}

export default BarChart;
