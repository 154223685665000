import React,{useState,useEffect}  from 'react';
import axios from "axios";
import {baseUrl,access_token} from '../../services/api';
import Image01 from '../../images/user-36-05.jpg';
import Image02 from '../../images/user-36-06.jpg';
import Image03 from '../../images/user-36-07.jpg';
import Image04 from '../../images/user-36-08.jpg';
import Image05 from '../../images/user-36-09.jpg';
import AppString from '../components/AppStiring';
function DashboardCard20({userType,status,refresh,setRefresh}) {
 const[ customers,setCustomers]=useState([])
  useEffect(()=>{

    try{

          axios
          .get(`${baseUrl}/analytics/getTheTotalnumberOfBannedUser`,{  headers: {
            'Authorization': `Bearer ${access_token}`
          }})
          
          .then((response) => {
    
              setCustomers(response.data)
              
     
      
          }
          
          )     
          .catch(err => {
            console.error(err);
            
          });
         
  
    } catch (error) {
      if(error.message === 'Network Error'){
          alert('no internet connection');
      }
}},[refresh]

)

const handleClick = (event, param) => {

  try{

    axios
    .patch(`${baseUrl}/admin/adminUpdate/${param}`,{
      "userStatus":"UNBANNED"
  },{  headers: {'Content-Type': 'application/json',
      'Authorization': `Bearer ${access_token}`
    },
  })
    
    .then((response) => {
  
     alert("USER UNBANNED SUCCESSFULLY!")
     setRefresh(!refresh)

    }
    
    )     
    .catch(err => {
      console.error(err);
      
    });
   
    

} catch (error) {
if(error.message === 'Network Error'){
    alert('no internet connection');
}
}

};

  return (
    <div style={{borderRadius: '15px'}} className="flex flex-col col-span-full sm:col-span-6 xl:col-span-6 bg-white shadow-lg rounded-xlg border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800">{userType+`:  ${customers.length==0?"":customers.length}`}</h2>
      </header>
      <div className="p-3" style={{height:"350px", overflow: 'scroll'}}>

        {/* Table */}
        {customers.length==0?<div style={{textAlign:'center',margin:'auto'}}>No Banned Users</div>:<div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-400 bg-slate-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Name</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Email</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Status</div>
                </th>
               
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-100">
              {
                customers.map(customer => {
                  return (
                    <tr key={customer._id}>
                      <td className="p-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
                            <img className="rounded-full" src={customer.userInfo.userProfileUrl===""?'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png':customer.userInfo.userProfileUrl}  style={{objectFit:'cover',width:"40px", height:"40px"}} alt={customer.userInfo.firstName} />
                          </div>
                          <div className="font-medium text-slate-800">
                            <AppString>
                            {customer.userInfo.firstName}
                            </AppString>
                            </div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left">{customer.googleInfo&&customer.googleInfo.email}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium text-green-500" onClick={event => handleClick(event, customer._id)}><button><strong style={{background: 'green', border: 'none', borderRadius: '2px', color: 'white', padding: '3px', fontSize: '11px'}} >{status}</strong></button></div>
                      </td>
                    
                    </tr>
                  )
                })
              }
            </tbody>
          </table>

        </div>}

      </div>
    </div>
  );
}

export default DashboardCard20;
