import React, { useState } from 'react';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import WelcomeBanner from '../partials/dashboard/WelcomeBanner';
import DashboardAvatars from '../partials/dashboard/DashboardAvatars';
import FilterButton from '../partials/actions/FilterButton';
import Datepicker from '../partials/actions/Datepicker';
import DashboardCard06 from '../partials/dashboard/DashboardCard06';
import DashboardCard08 from '../partials/dashboard/DashboardCard08';
import DashboardCard10 from '../partials/dashboard/DashboardCard10';
import DashboardCard20 from '../partials/dashboard/DashboardCard20';
import DashboardCard30 from '../partials/dashboard/DashboardCard30';
import BookTalk01 from '../partials/BookTalks/BookTalk01';
import BookTalk02 from '../partials/BookTalks/BookTalk02';
import BookTalk03 from '../partials/BookTalks/BookTalk03';
import BookTalk04 from '../partials/BookTalks/BookTalk04';
import BookTalk05 from '../partials/BookTalks/BookTalk05';
import BookTalk06 from '../partials/BookTalks/BookTalk06';

function BookTalks() {
  const[refresh,setRefresh]=useState(true)

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
// useEffect(()=>{

// },[])
const handleStartDate=(val)=>{
  setStartDate(val)
}


  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            <WelcomeBanner />

            {/* Dashboard actions */}
            {/* <div className="sm:flex sm:justify-between sm:items-center mb-8"> */}

              {/* Left: Avatars */}
              <div></div>
              {/* <DashboardAvatars /> */}

              {/* Right: Actions */}
              <div className="grid grid-cols-12 gap-6"> 
                {/* Filter button */}
                 {/* <FilterButton /> */}
                {/* Datepicker built with flatpickr */}
                {/* Add view button */}
                {/* <Datepicker setStartDate={handleStartDate} /> */}
                 
                {/* <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                    <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="hidden xs:block ml-2">Add view</span>
                </button>                 */}
             

                <BookTalk01/>
                <BookTalk02/>
                <BookTalk03/>
                <BookTalk04/>
                <BookTalk05/>
                <BookTalk06/> 
                </div>
          </div>
        </main>

        {/* <Banner /> */}

      </div>
    </div>
  );
}

export default BookTalks;