import React, { useState } from 'react';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import WelcomeBanner from '../partials/dashboard/WelcomeBanner';
import DashboardCard33 from '../partials/dashboard/DashboardCard33';

function AddFeaturedReview() {

  const [sidebarOpen, setSidebarOpen] = useState(true);

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            <WelcomeBanner />

 
              <div></div>
      
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"> 
            
              </div>
              <DashboardCard33/>
                {/* <AddReviewForm/> */}
          </div>
        </main>

        {/* <Banner /> */}

      </div>
    </div>
  );
}

export default AddFeaturedReview;