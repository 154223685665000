import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "axios";
import { baseUrl, access_token } from "../../services/api";
import SearchIcon from "@mui/icons-material/Search";
import AppString from "../components/AppStiring";
import { Link } from "react-router-dom";
import BookChunkModal from "../components/BookChunkModal";
import {
  getBookChunks,
  updateBookChunks,
} from "../../services/bookchunk.service";
import {
  getMoniroFeaturedBooks,
  getMoniroPopularBooks,
  getMoniroRecommendedBooks,
  getPopularBooks,
  getPopularGenres,
  inViteGlobal,
} from "../../services/books.service";
function DashboardCard32() {
  const [searchVal, setSearchVal] = useState();
  const [refresh, setRefresh] = useState(false);
  const [bookExist, setBookExist] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [searchedUser, setSearchedUser] = useState([]);
  const [afterSearch, setAfterSearch] = useState(0);
  const [deletebook, setDeletebook] = useState(false);
  const [popularBooks, setPopularBooks] = useState(undefined);
  const [popularGenres, setPopularGenres] = useState(undefined);
  const [popularGenre, setPopularGenre] = useState("");
  const [showGenre, setShowGenre] = useState(undefined);
  const [moniroFeaturedBooks, setMoniroFeaturedBooks] = useState([]);
  const [moniroPopularBooks, setMoniroPopularBooks] = useState([]);
  const [moniroRecommendedBooks, setMoniroRecommendedBooks] = useState([]);
  const [moniroTrendingBooks, setMoniroTrendingBooks] = useState([]);
  useEffect(() => {
    getPopularBooks()
      .then((response) => {
        // console.log("RESPPONSE.getPopularBooks", response);
        setPopularBooks(response.data);
      })
      .catch((reason) =>
        console.log("ERROR WITH REASON.getPopularBooks:", reason)
      );
    getPopularGenres()
      .then((response) => {
        setPopularGenres(response.data);
      })
      .catch((reason) =>
        console.log("ERROR WITH REASON.getPopularGenres:", reason)
      );
    getMoniroFeaturedBooks()
      .then((response) => {
        console.log("response.data.getMoniroFeaturedBooks", response.data);
        setMoniroFeaturedBooks(response.data);
      })
      .catch((reason) =>
        console.log("ERROR WITH REASON.getMoniroFeaturedBooks:", reason)
      );
    getMoniroPopularBooks()
      .then((response) => {
        setMoniroPopularBooks(response.data);
      })
      .catch((reason) =>
        console.log("ERROR WITH REASON.getMoniroPopularBooks:", reason)
      );
    getMoniroRecommendedBooks()
      .then((response) => {
        setMoniroRecommendedBooks(response.data);
      })
      .catch((reason) =>
        console.log("ERROR WITH REASON.getMoniroRecommendedBooks:", reason)
      );
    getBookChunks()
      .then((response) => {
        console.log('response.data',response.data);
        const chunkResponse = Object.keys(response.data).map((e)=>response.data[e]);
        let theTrend = chunkResponse.find(({chunkValue})=>chunkValue=='TRENDING_GENRE_BOOK');
        setPopularGenre(theTrend.chunkSubTitle);
        setMoniroTrendingBooks(theTrend.books);
      })
      .catch((reason) => console.log("ERROR WITH REASON.getBookChunks:", reason));
  }, []);
  useEffect(() => {
    try {
      // axios
      //   .get(`${baseUrl}/admin/findFeaturedBook`, {
      //     headers: {
      //       Authorization: `Bearer ${access_token}`,
      //     },
      //   })
      //   .then((response) => {
      //     console.log("responsesss", response);
      //     setCustomers(response.data);
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });
    } catch (error) {
      if (error.message === "Network Error") {
        alert("no internet connection");
      }
    }
  }, [refresh]);

  const handleClick = (event, param) => {
    // "author":customer.author,"isbn":customer.isbn,"title":customer.title,"description":customer.description,"subtitle":customer.subtitle,"imageUrl":customer.imageUrl,"language":customer.language,"publisher":customer.publisher,"publishedDate":customer.publishedDate,"_id":customer._id,"genre":customer.genre
  };

  const handlePremium = (event, param) => {
    try {
      axios
        .put(
          `${baseUrl}/admin/changeToFreemiumORPreemium/${param.id}`,
          {
            usertype: `${param.value == "FREEMIUM" ? "PREMIUM" : "FREEMIUM"}`,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        )

        .then((response) => {
          alert(
            `USER Type Changed to  ${
              param.value == "FREEMIUM" ? "PREMIUM" : "FREEMIUM"
            } SUCCESSFULLY!`
          );
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      if (error.message === "Network Error") {
        alert("no internet connection");
      }
    }
  };

  const handleDelete = (bookId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this book?"
    );
    if (isConfirmed) {
      axios
        .delete(`${baseUrl}/admin/delete/${bookId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((response) => {
          alert("Book deleted successfully:");
          window.location.reload();
          console.log("Book deleted successfully:", response.data);
        })
        .catch((error) => {
          // Handle error response
          console.error("Error deleting book:", error);
        });
    }
  };
  const handleSearch2 = async (event, param) => {
    setDeletebook(true);
    try {
      const response = await axios.get(
        `${baseUrl}/admin/search?query=${param}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      // Check if 'data' and 'data.data' properties exist in the response
      if (!response.data || !response.data.success || !response.data.data) {
        console.log("Invalid response format or no data found.");
        setSearchedUser([]);
        return;
      }

      console.log("Custom search response:", response.data.data);

      // Transform the data into the desired format
      const cust = response.data.data.map((book) => ({
        _id: book._id,
        title: book.title || "",
        author: book.author || "Not Known",
        imageUrl: book.imageUrl || "",
        description: book.description || "",
        language: book.language || "",
        publisher: book.publisher || "",
        publishedDate: book.publishedDate || "",
        genre: book.genre || "",
        subtitle: book.subTitle || "",
        isbn: book.isbn || "",
        bookAddedAs: book.bookAddedAs || "",
      }));

      console.log("Transformed data:", cust);

      // Update state with the transformed data
      setSearchedUser(cust);
      setRefresh(!refresh);
    } catch (error) {
      // Handle errors
      setSearchedUser([]);
      console.error(error);

      if (error.message === "Network Error") {
        alert("No internet connection");
      }
    }
  };

  const handleSearch = async (event, param) => {
    setDeletebook(false);

    try {
      await axios
        .get(
          `https://www.googleapis.com/books/v1/volumes?q=${param}&startIndex=1&maxResults=10`
        )

        .then((response) => {
          console.log("google respn", response.data.items);
          setSearchedUser(response.data.items);
          let bookData = response.data.items;
          let cust = [];
          for (let index = 0; index < bookData.length; index++) {
            cust.push({
              _id: index,
              title: bookData[index].volumeInfo.title,
              author: bookData[index].volumeInfo.authors
                ? bookData[index].volumeInfo.authors.toString()
                : "Not Known",
              imageUrl: bookData[index].volumeInfo.imageLinks
                ? bookData[index].volumeInfo.imageLinks.smallThumbnail
                : "",
              description: bookData[index].volumeInfo.description,
              laungage: bookData[index].volumeInfo.language,
              publisher: bookData[index].volumeInfo.publisher,
              publishedDate: bookData[index].volumeInfo.publishedDate,
              genre: bookData[index].volumeInfo.categories
                ? bookData[index].volumeInfo.categories.toString()
                : "",
              subtitle: bookData[index].volumeInfo.subtitle,
              isbn: bookData[index].volumeInfo.industryIdentifiers
                ? bookData[index].volumeInfo.industryIdentifiers[0].identifier
                : "",
              bookAddedAs: "",
            });
          }
          console.log(cust);
          setSearchedUser(cust);
          console.log("customer", customers);
          setRefresh(!refresh);
        })
        .catch((err) => {
          setSearchedUser([]);
          console.error(err);
        });
    } catch (error) {
      if (error.message === "Network Error") {
        alert("no internet connection");
      }
    }
  };
  function handleChange(event) {
    setSearchVal(event.target.value);
    if (!searchVal) {
      setRefresh(true);
    }
    handleSearch(event, event.target.value);
  }
  function handleChange2(event) {
    setSearchVal(event.target.value);
    // if(!searchVal){
    //   setRefresh(true)
    // }
    handleSearch2(event, event.target.value);
  }
  return (
    <div className="col-span-full xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <Link
          to="/addFeaturedBookForm"
          state={{ newbook: "1" }}
          style={{
            float: "initial",
            padding: 5,
            color: "whitesmoke",
            textDecoration: "none",
            background: "green",
            border: "none",
            borderRadius: 3,
            fontFamily: "sans-serif",
            fontSize: 16,
          }}
        >
          Add New Book
        </Link>
        {/* <button onClick={()=>inViteGlobal('65b692937d43a849e40905e9').then((res)=>console.log('KOKOKOKOKOKOKO',res))}>Invite</button> */}
      </header>
      <header className="px-5 py-4 border-b border-slate-100">
        <h2
          className="font-semibold text-slate-800"
          style={{ width: "15%", display: "inline-block" }}
        ></h2>
        <input
          placeholder="Search Book From Database"
          style={{
            float: "left",
            borderRadius: "10px",
            paddingLeft: "5px",
            border: "solid",
          }}
          onChange={handleChange2}
        />
        {/* <SearchIcon style={{float:"left",margin:"5px"}} onClick={event => handleSearch2(event, searchVal)}/>  */}

        <input
          placeholder="Search New Book"
          style={{
            float: "right",
            borderRadius: "10px",
            paddingRight: "5px",
            border: "solid",
          }}
          onChange={handleChange}
        />
        {/* <SearchIcon style={{float:"right",margin:"5px"}} onClick={event => handleSearch(event, searchVal)}/> */}
      </header>
      <div className="p-3">
        {/* Table */}
        {
          <Tabs
            defaultActiveKey={1}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            {[
              "FEATURED_BOOK",
              "RECOMMENDED_BOOK",
              "POPULAR_BOOK",
              "TRENDING_GENRE_BOOK",
            ].map((chunkValue) => {
              let books, chunkTitle, chunkSubTitle;
              switch (chunkValue) {
                case "FEATURED_BOOK":
                  books = moniroFeaturedBooks;
                  chunkTitle = "Featured books";
                  break;
                case "RECOMMENDED_BOOK":
                  books = moniroRecommendedBooks;
                  chunkTitle = "Recommended books";
                  break;
                case "POPULAR_BOOK":
                  books = moniroPopularBooks;
                  chunkTitle = "Popular books";
                  break;
                case "TRENDING_GENRE_BOOK":
                  books = moniroTrendingBooks;
                  chunkTitle = "Trending genre books";
                  chunkSubTitle = "The genre";
                  break;
                default:
              }
              // console.log(chunkTitle,chunkValue,books.map(({imageUrl})=>imageUrl));
              return (
                <Tab
                  eventKey={chunkValue}
                  title={chunkTitle}
                >
                  {chunkValue === "TRENDING_GENRE_BOOK" && (
                    <div>
                      Genre:
                      <input
                        value={popularGenre}
                        placeholder="Search New Book"
                        style={{
                          borderRadius: "10px",
                          paddingLeft: "5px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          border: "solid",
                          borderWidth: "1px",
                        }}
                        onChange={(v) => setPopularGenre(v.target.value)}
                      />
                      {chunkSubTitle !== popularGenre && (
                        <span className="text-left font-medium text-green-500 ">
                          <button
                            onClick={() => {
                              updateBookChunks(chunkTitle,popularGenre,chunkValue).then((response)=>{
                              })
                            }}
                          >
                            Save
                          </button>
                        </span>
                      )}
                    </div>
                  )}
                  <table className="table-auto w-full">
                    <thead className="text-xs font-semibold uppercase text-slate-400 bg-slate-50">
                      {/* <tr>
                          <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-left">
                              {chunkTitle}
                            </div>
                          </th>
                        </tr> */}
                      <tr>
                        <th className="p-2 whitespace-nowrap">
                          <div className="font-semibold text-left">Title</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                          <div className="font-semibold text-left">Author</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                          <div className="font-semibold text-left">Status</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {books.map((book) => {
                        const popular = moniroPopularBooks
                          .map(({ _id }) => _id)
                          .includes(book._id);
                        const recommended = moniroRecommendedBooks
                          .map(({ _id }) => _id)
                          .includes(book._id);
                        const featured = moniroFeaturedBooks
                          .map(({ _id }) => _id)
                          .includes(book._id);
                        const trendingGenre = moniroTrendingBooks
                          .map(({ _id }) => _id)
                          .includes(book._id);
                        return (
                          book && (
                            <tr key={book._id}>
                              <td className="p-2 whitespace-nowrap">
                                <div className="flex items-center">
                                  <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
                                    <img
                                      className="rounded-full"
                                      style={{
                                        objectFit: "cover",
                                        width: "40px",
                                        height: "40px",
                                      }}
                                      src={
                                        book.imageUrl === ""
                                          ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                                          : book.imageUrl
                                      }
                                      alt={book.title}
                                    />
                                  </div>
                                  <div className="font-medium text-slate-800">
                                    <AppString>{book.title}</AppString>{" "}
                                  </div>
                                </div>
                              </td>
                              <td className="p-2 whitespace-nowrap">
                                <div className="text-left">
                                  <AppString>{book.author}</AppString>
                                </div>
                              </td>
                              <td
                                style={{ display: "flex", width: "50px" }}
                                className="p-2 whitespace-nowrap"
                              >
                                {moniroPopularBooks && (
                                  <BookChunkModal
                                  bookTitle={book.title}
                                    key={Date()}
                                    popular={popular}
                                    recommended={recommended}
                                    featured={featured}
                                    trendingGenre={trendingGenre}
                                    bookId={book._id}
                                    onUpdate={(
                                      isFeatured,
                                      isPopular,
                                      isRecommended,
                                      isTrendingGenre
                                    ) => {
                                      if (isFeatured !== featured)
                                        !isFeatured
                                          ? setMoniroFeaturedBooks(
                                              moniroFeaturedBooks.filter(
                                                ({ _id }) => _id !== book._id
                                              )
                                            )
                                          : setMoniroFeaturedBooks([
                                              ...moniroFeaturedBooks,
                                              book,
                                            ]);
                                      if (isRecommended !== recommended)
                                        !isRecommended
                                          ? setMoniroRecommendedBooks(
                                              moniroRecommendedBooks.filter(
                                                ({ _id }) => _id !== book._id
                                              )
                                            )
                                          : setMoniroRecommendedBooks([
                                              ...moniroRecommendedBooks,
                                              book,
                                            ]);
                                      if (isPopular !== popular)
                                        !isPopular
                                          ? setMoniroPopularBooks(
                                              moniroPopularBooks.filter(
                                                ({ _id }) => _id !== book._id
                                              )
                                            )
                                          : setMoniroPopularBooks([
                                              ...moniroPopularBooks,
                                              book,
                                            ]);
                                      if (isTrendingGenre !== trendingGenre)
                                        !isTrendingGenre
                                          ? setMoniroTrendingBooks(
                                              moniroTrendingBooks.filter(
                                                ({ _id }) => _id !== book._id
                                              )
                                            )
                                          : setMoniroTrendingBooks([
                                              ...moniroTrendingBooks,
                                              book,
                                            ]);
                                    }}
                                  />
                                )}
                                <Link
                                  style={{ flex: 1, marginLeft: 10 }}
                                  to="/addFeaturedBookForm"
                                  state={{
                                    authors: book.author,
                                    isbns: book.isbn,
                                    titles: book.title,
                                    descriptions: book.description,
                                    subtitles: book.subtitle,
                                    imageUrls: book.imageUrl,
                                    languages: book.language,
                                    publishers: book.publisher,
                                    publishedDates: book.publishedDate,
                                    _ids: book._id,
                                    genres: book.genre,
                                    bookAddedAs: book.bookAddedAs,
                                  }}
                                >
                                  <div className="text-left font-medium text-green-500 ">
                                    <button>{"Edit"}</button>
                                  </div>
                                </Link>
                                <div
                                  style={{ flex: 1, marginInlineStart: 5 }}
                                  className="text-right font-medium text-red-500"
                                >
                                  <button
                                    onClick={() => handleDelete(book._id)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        );
                      })}
                      {chunkValue === "TRENDING_GENRE_BOOK" &&
                        popularGenres && <br />}
                      {chunkValue === "TRENDING_GENRE_BOOK" &&
                        popularGenres && (
                          <button
                            className="text-blue-500"
                            onClick={() => {
                              setShowGenre(!showGenre);
                            }}
                          >
                            {showGenre ? "Hide suggestion" : "Show suggestion"}
                          </button>
                        )}
                      {chunkValue === "TRENDING_GENRE_BOOK" &&
                        popularGenres && <br />}
                      {chunkValue === "TRENDING_GENRE_BOOK" &&
                        popularGenres && <br />}
                      {chunkValue === "TRENDING_GENRE_BOOK" &&
                        popularGenres &&
                        showGenre &&
                        popularGenres.map(
                          ({ _id, totalUsers, totalDocuments }) => (
                            <div className="text-left font-medium">
                              <button
                                className={
                                  _id !== ""
                                    ? _id === chunkSubTitle
                                      ? "text-green-700"
                                      : "text-blue-400"
                                    : "text-red-500"
                                }
                                onClick={() => {
                                  console.log("genre selected: ", _id);
                                  setPopularGenre(_id);
                                }}
                              >
                                {_id !== "" ? _id : "No genre"}
                              </button>
                              <div>
                                {totalDocuments} books and {totalUsers} total
                                users.
                              </div>
                            </div>
                          )
                        )}
                      {chunkValue === "POPULAR_BOOK" && popularBooks && <br />}
                      {chunkValue === "POPULAR_BOOK" && popularBooks && (
                        <button
                          className="text-blue-500"
                          onClick={() => {
                            setShowGenre(!showGenre);
                          }}
                        >
                          {showGenre ? "Hide suggestion" : "Show suggestion"}
                        </button>
                      )}
                      {chunkValue === "POPULAR_BOOK" && popularBooks && <br />}
                      {chunkValue === "POPULAR_BOOK" && popularBooks && <br />}
                      {chunkValue === "POPULAR_BOOK" &&
                        popularBooks &&
                        showGenre && <tr>Suggested</tr>}
                      {chunkValue === "POPULAR_BOOK" &&
                        popularBooks &&
                        showGenre &&
                        popularBooks.map((book) => {
                          const popular = moniroPopularBooks
                            .map(({ _id }) => _id)
                            .includes(book._id);
                          const recommended = moniroRecommendedBooks
                            .map(({ _id }) => _id)
                            .includes(book._id);
                          const featured = moniroFeaturedBooks
                            .map(({ _id }) => _id)
                            .includes(book._id);
                          const trendingGenre = moniroTrendingBooks
                            .map(({ _id }) => _id)
                            .includes(book._id);
                          return (
                            book && (
                              <tr key={book._id}>
                                <td className="p-2 whitespace-nowrap">
                                  <div className="flex items-center">
                                    <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
                                      <img
                                        className="rounded-full"
                                        style={{
                                          objectFit: "cover",
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        src={
                                          book.imageUrl === ""
                                            ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                                            : book.imageUrl
                                        }
                                        alt={book.title}
                                      />
                                    </div>
                                    <div className="font-medium text-slate-800">
                                      <AppString>{book.title}</AppString>{" "}
                                    </div>
                                  </div>
                                </td>
                                <td className="p-2 whitespace-nowrap">
                                  <div className="text-left">
                                    <AppString>{book.author}</AppString>
                                  </div>
                                </td>
                                <td
                                  style={{ display: "flex", width: "50px" }}
                                  className="p-2 whitespace-nowrap"
                                >
                                  {moniroPopularBooks && (
                                    <BookChunkModal
                                    bookTitle={book.title}
                                      key={Date()}
                                      popular={popular}
                                      recommended={recommended}
                                      featured={featured}
                                      trendingGenre={trendingGenre}
                                      bookId={book._id}
                                      onUpdate={(
                                        isFeatured,
                                        isPopular,
                                        isRecommended,
                                        isTrendingGenre
                                      ) => {
                                        if (isFeatured !== featured)
                                          !isFeatured
                                            ? setMoniroFeaturedBooks(
                                                moniroFeaturedBooks.filter(
                                                  ({ _id }) => _id !== book._id
                                                )
                                              )
                                            : setMoniroFeaturedBooks([
                                                ...moniroFeaturedBooks,
                                                book,
                                              ]);
                                        if (isRecommended !== recommended)
                                          !isRecommended
                                            ? setMoniroRecommendedBooks(
                                                moniroRecommendedBooks.filter(
                                                  ({ _id }) => _id !== book._id
                                                )
                                              )
                                            : setMoniroRecommendedBooks([
                                                ...moniroRecommendedBooks,
                                                book,
                                              ]);
                                        if (isPopular !== popular)
                                          !isPopular
                                            ? setMoniroPopularBooks(
                                                moniroPopularBooks.filter(
                                                  ({ _id }) => _id !== book._id
                                                )
                                              )
                                            : setMoniroPopularBooks([
                                                ...moniroPopularBooks,
                                                book,
                                              ]);
                                        if (isTrendingGenre !== trendingGenre)
                                          !isTrendingGenre
                                            ? setMoniroTrendingBooks(
                                                moniroTrendingBooks.filter(
                                                  ({ _id }) => _id !== book._id
                                                )
                                              )
                                            : setMoniroTrendingBooks([
                                                ...moniroTrendingBooks,
                                                book,
                                              ]);
                                      }}
                                    />
                                  )}
                                  <Link
                                    style={{ flex: 1, marginLeft: 10 }}
                                    to="/addFeaturedBookForm"
                                    state={{
                                      authors: book.author,
                                      isbns: book.isbn,
                                      titles: book.title,
                                      descriptions: book.description,
                                      subtitles: book.subtitle,
                                      imageUrls: book.imageUrl,
                                      languages: book.language,
                                      publishers: book.publisher,
                                      publishedDates: book.publishedDate,
                                      _ids: book._id,
                                      genres: book.genre,
                                      bookAddedAs: book.bookAddedAs,
                                    }}
                                  >
                                    <div className="text-left font-medium text-green-500 ">
                                      <button>{"Edit"}</button>
                                    </div>
                                  </Link>
                                  <div
                                    style={{ flex: 1, marginInlineStart: 5 }}
                                    className="text-right font-medium text-red-500"
                                  >
                                    <button
                                      onClick={() => handleDelete(book._id)}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            )
                          );
                        })}
                    </tbody>
                  </table>
                </Tab>
              );
            })}
          </Tabs>
        }
        <br />
        <br />
        <br />
        <br />
        <div
          className="overflow-x-auto"
          style={{ height: "350px", overflow: "scroll" }}
        >
          <table className="table-auto w-full">
            {/* Table header */}
            {searchedUser.length > 0 && (
              <thead className="text-xs font-semibold uppercase text-slate-400 bg-slate-50">
                <tr>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Title</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Author</div>
                  </th>
                  <th className="p-2 whitespace-nowrap">
                    <div className="font-semibold text-left">Status</div>
                  </th>
                  {/* <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Total number of report</div>
                </th> */}
                </tr>
              </thead>
            )}
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-100">
              {searchedUser.length === 0
                ? customers.map((book) => {
                    // try {
                    //   // Check if the book exists in the database
                    //   const bookInDB = axios.get(`${baseUrl}/admin/books/${customer.isbn}`, {
                    //     headers: {
                    //       'Authorization': `Bearer ${access_token}`
                    //     }
                    //   });

                    //   const existsInSearchedUser = bookInDB.data ? true : false;
                    const popular = moniroPopularBooks
                      .map(({ _id }) => _id)
                      .includes(book._id);
                    const recommended = moniroRecommendedBooks
                      .map(({ _id }) => _id)
                      .includes(book._id);
                    const featured = moniroFeaturedBooks
                      .map(({ _id }) => _id)
                      .includes(book._id);
                    const trendingGenre = moniroTrendingBooks
                      .map(({ _id }) => _id)
                      .includes(book._id);
                    return (
                      <tr key={book._id}>
                        <td className="p-2 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
                              <img
                                className="rounded-full"
                                style={{
                                  objectFit: "cover",
                                  width: "40px",
                                  height: "40px",
                                }}
                                src={
                                  book.imageUrl === ""
                                    ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                                    : book.imageUrl
                                }
                                alt={book.title}
                              />
                            </div>
                            <div className="font-medium text-slate-800">
                              <AppString>{book.title}</AppString>{" "}
                            </div>
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div className="text-left">
                            <AppString>{book.author}</AppString>
                          </div>
                        </td>
                        <td
                          style={{ display: "flex", width: "50px" }}
                          className="p-2 whitespace-nowrap"
                        >
                          {moniroPopularBooks && (
                            <BookChunkModal
                            bookTitle={book.title}
                              key={Date()}
                              popular={popular}
                              recommended={recommended}
                              featured={featured}
                              trendingGenre={trendingGenre}
                              bookId={book._id}
                              onUpdate={(
                                isFeatured,
                                isPopular,
                                isRecommended,
                                isTrendingGenre
                              ) => {
                                if (isFeatured !== featured)
                                  !isFeatured
                                    ? setMoniroFeaturedBooks(
                                        moniroFeaturedBooks.filter(
                                          ({ _id }) => _id !== book._id
                                        )
                                      )
                                    : setMoniroFeaturedBooks([
                                        ...moniroFeaturedBooks,
                                        book,
                                      ]);
                                if (isRecommended !== recommended)
                                  !isRecommended
                                    ? setMoniroRecommendedBooks(
                                        moniroRecommendedBooks.filter(
                                          ({ _id }) => _id !== book._id
                                        )
                                      )
                                    : setMoniroRecommendedBooks([
                                        ...moniroRecommendedBooks,
                                        book,
                                      ]);
                                if (isPopular !== popular)
                                  !isPopular
                                    ? setMoniroPopularBooks(
                                        moniroPopularBooks.filter(
                                          ({ _id }) => _id !== book._id
                                        )
                                      )
                                    : setMoniroPopularBooks([
                                        ...moniroPopularBooks,
                                        book,
                                      ]);
                                if (isTrendingGenre !== trendingGenre)
                                  !isTrendingGenre
                                    ? setMoniroTrendingBooks(
                                        moniroTrendingBooks.filter(
                                          ({ _id }) => _id !== book._id
                                        )
                                      )
                                    : setMoniroTrendingBooks([
                                        ...moniroTrendingBooks,
                                        book,
                                      ]);
                              }}
                            />
                          )}
                          <Link
                            style={{ flex: 1 }}
                            to="/addFeaturedBookForm"
                            state={{
                              authors: book.author,
                              isbns: book.isbn,
                              titles: book.title,
                              descriptions: book.description,
                              subtitles: book.subtitle,
                              imageUrls: book.imageUrl,
                              languages: book.language,
                              publishers: book.publisher,
                              publishedDates: book.publishedDate,
                              _ids: book._id,
                              genres: book.genre,
                              bookAddedAs: book.bookAddedAs,
                            }}
                          >
                            <div
                              className="text-left font-medium text-green-500 "
                              // onClick={event => handleClick(event, {"author":book.author,"isbn":book.isbn,"title":book.title,"description":book.description,"subtitle":book.subtitle,"imageUrl":book.imageUrl,"language":book.language,"publisher":book.publisher,"publishedDate":book.publishedDate,"_id":book._id,"genre":book.genre })}
                            >
                              <button>{"Edit"}</button>
                            </div>
                          </Link>

                          {/* {existsInSearchedUser && ( */}
                          <div
                            style={{ flex: 1, marginInlineStart: 5 }}
                            className="text-right font-medium text-red-500"
                          >
                            <button onClick={() => handleDelete(book._id)}>
                              Delete
                            </button>
                          </div>
                          {/* )} */}
                          {/* ,isbns:{book.isbn} ,titles:{book.title} ,descriptions:{book.description}, subtitles:{book.subtitle} ,imageUrls:{book.imageUrl} ,languages:{book.language}, publishers:{book.publisher}, publishedDates:{book.publishedDate}, _ids:{book._id}, genres:{book.genre}      */}
                        </td>
                        {/* <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium text-green-500 " onClick={event => handleClick(event, {"id":book._id,"value":book.userStatus})}><button >{book.userStatus=="BANNED"?"Unban user":"Ban user"}</button></div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium text-green-500 " onClick={event => handlePremium(event, {"id":book._id,"value":book.usertype})}><button >{book.usertype=="FREEMIUM"?"Make PREMIUM":"Make FREEMIUM"}</button></div>
                      </td> */}
                        {/* <td className="p-2 whitespace-nowrap">
                        <div className="text-lg text-center">{book.totalReportnumber}</div>
                      </td> */}
                      </tr>
                    );
                    // } catch (error) {
                    //   console.error('Error checking book existence:', error);
                    //   // Handle error if needed
                    //   return null;
                    // }
                  })
                : searchedUser.map((book) => {
                    // try {
                    //   const response = axios.get(`${baseUrl}/admin/books/${book.isbn}`, {
                    //     headers: {
                    //       'Authorization': `Bearer ${access_token}`
                    //     }
                    //   });

                    //   console.log("Book data for ISBN:", book.isbn, response.data);

                    //   if (response.data) {
                    //     console.log("Book exists");
                    //     setBookExist(true);
                    //   } else {
                    //     console.log("Book does not exist");
                    //     setBookExist(false);
                    //   }
                    // } catch (error) {
                    //   console.error("Error fetching book data:", error);
                    //   // Handle errors if needed
                    // }
                    const popular = moniroPopularBooks
                      .map(({ _id }) => _id)
                      .includes(book._id);
                    const recommended = moniroRecommendedBooks
                      .map(({ _id }) => _id)
                      .includes(book._id);
                    const featured = moniroFeaturedBooks
                      .map(({ _id }) => _id)
                      .includes(book._id);
                    const trendingGenre = moniroTrendingBooks
                      .map(({ _id }) => _id)
                      .includes(book._id);
                    return (
                      <tr key={book._id}>
                        <td className="p-2 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
                              <img
                                className="rounded-full"
                                style={{
                                  objectFit: "cover",
                                  width: "40px",
                                  height: "40px",
                                }}
                                src={
                                  book.imageUrl === ""
                                    ? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                                    : book.imageUrl
                                }
                                alt={book.title}
                              />
                            </div>
                            <div className="font-medium text-slate-800">
                              <AppString>{book.title}</AppString>
                            </div>
                          </div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                          <div className="text-left">
                            <AppString>{book.author}</AppString>
                          </div>
                        </td>
                        <td
                          style={{ display: "flex", width: "50px" }}
                          className="p-2 whitespace-nowrap"
                        >
                          {deletebook && (
                            <BookChunkModal
                            bookTitle={book.title}
                              key={Date()}
                              popular={popular}
                              recommended={recommended}
                              featured={featured}
                              trendingGenre={trendingGenre}
                              bookId={book._id}
                              onUpdate={(
                                isFeatured,
                                isPopular,
                                isRecommended,
                                isTrendingGenre
                              ) => {
                                if (isFeatured !== featured)
                                  !isFeatured
                                    ? setMoniroFeaturedBooks(
                                        moniroFeaturedBooks.filter(
                                          ({ _id }) => _id !== book._id
                                        )
                                      )
                                    : setMoniroFeaturedBooks([
                                        ...moniroFeaturedBooks,
                                        book,
                                      ]);
                                if (isRecommended !== recommended)
                                  !isRecommended
                                    ? setMoniroRecommendedBooks(
                                        moniroRecommendedBooks.filter(
                                          ({ _id }) => _id !== book._id
                                        )
                                      )
                                    : setMoniroRecommendedBooks([
                                        ...moniroRecommendedBooks,
                                        book,
                                      ]);
                                if (isPopular !== popular)
                                  !isPopular
                                    ? setMoniroPopularBooks(
                                        moniroPopularBooks.filter(
                                          ({ _id }) => _id !== book._id
                                        )
                                      )
                                    : setMoniroPopularBooks([
                                        ...moniroPopularBooks,
                                        book,
                                      ]);
                                if (isTrendingGenre !== trendingGenre)
                                  !isTrendingGenre
                                    ? setMoniroTrendingBooks(
                                        moniroTrendingBooks.filter(
                                          ({ _id }) => _id !== book._id
                                        )
                                      )
                                    : setMoniroTrendingBooks([
                                        ...moniroTrendingBooks,
                                        book,
                                      ]);
                              }}
                            />
                          )}
                          <Link
                            style={{ flex: 1, marginLeft: deletebook && 10 }}
                            to="/addFeaturedBookForm"
                            state={{
                              authors: book.author,
                              isbns: book.isbn,
                              titles: book.title,
                              descriptions: book.description,
                              subtitles: book.subtitle,
                              imageUrls: book.imageUrl,
                              languages: book.language,
                              publishers: book.publisher,
                              publishedDates: book.publishedDate,
                              _ids: book._id,
                              genres: book.genre,
                              bookAddedAs: book.bookAddedAs,
                            }}
                          >
                            <div className="text-left font-medium text-green-500 ">
                              <button>{"Edit"}</button>
                            </div>
                          </Link>

                          {deletebook ? (
                            <div
                              style={{ flex: 1, marginInlineStart: 5 }}
                              className="text-right font-medium text-red-500"
                            >
                              <button onClick={() => handleDelete(book._id)}>
                                Delete
                              </button>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DashboardCard32;
